import React from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const Password = ({ passwordModal, setPasswordModal, username, sub }) => {
  const classes = useStyles();
  const handleClose = () => {
    setPasswordModal(false);
  };

  // validating the form
  const validate = (newPass, confirmPass) => {
    console.log(newPass, confirmPass);
    if (newPass !== confirmPass) {
      toast.dark('Password doesn\'t match', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return false;
    }
    if (newPass.length < 5) {
      toast.dark('Password length must be more than 5 characters', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return false;
    }
    return true;
  };

  // handle reset password
  const handlePassword = (e) => {
    e.preventDefault();
    const isValidate = validate(e.target.new.value, e.target.newConfirm.value);
    if (isValidate === true) {
      let url = '/agent/reset';
      if (sub === 'Y' || username.indexOf('@') > -1) url = '/agent/resetSub';
      axiosInstance
        .post(url, null, {
          params: {
            username: username,
            current: e.target.current.value,
            newpwd: e.target.new.value,
          },
        })
        .then((res) => {
          if (res.status === 200){
            console.log('password reset :', res);
            toast.success('Success!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            setPasswordModal(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Modal
      open={passwordModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form action="" onSubmit={handlePassword}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className="bg-primary text-light font-weight-bold"
                >
                  Reset Password {username}
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              <tr>
                <td className="text-body font-weight-bold">Your Password</td>
                <td>
                  <input type="password" id="current" required />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">
                  New Reset Password
                </td>
                <td>
                  <input type="password" id="new" required />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">
                  Confirm Reset Password
                </td>
                <td>
                  <input type="password" id="newConfirm" required />
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-sm btn-primary mx-3 px-3 py-1"
                    />

                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        setPasswordModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </Modal>
  );
};

export default Password;
