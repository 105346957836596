import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { Link, useHistory } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import moment from 'moment';

const Dashboard = ({User}) => {
  const [detail, setDetail] = useState(null);
  const [operationLog, setOperationLog] = useState();
  const history = useHistory();
  const handleOpLog = (id) => {
    window.open(`/credit?id=${id}`, 'blank');
  };
  useEffect(() => {
    axiosInstance
      .get('agent/dashboard')
      .then((res) => {
        setDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });

    // own op log
    axiosInstance
      .get('agent/agents/ownOperationLog', {
        params: {
          startDate: '19-11-2020',
          endDate: '19-11-2021',
          pageSize: '10',
        },
      })
      .then((res) => {
        setOperationLog(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navbar User={User} />

      {detail !== null ? (
        <>
          <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
            <h5>My Dashboard</h5>
          </div>

          <div className="row mt-3 px-3">
            {/* <div className="col-xs-6"> */}
            <div className="table-responsive-sm col-md-6">
              <table className="table table-sm table-striped table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    <th scope="col" colSpan={2}>
                      Credit Info &nbsp;&nbsp;&nbsp;
                      <Link
                        className="text-white text-underline"
                        onClick={() => {
                          handleOpLog(detail.id);
                        }}
                      >
                        <u>Detail</u>
                      </Link>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-dark text-center">Currency</td>
                    <td className="text-dark text-center">{detail.currency}</td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Beginning</td>
                    <td
                      className={`text-dark text-right${
                        detail.creditSummary.beginning < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.creditSummary.beginning}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Net W/L</td>
                    <td
                      className={`text-dark text-right${
                        detail.creditSummary.netWinLose < 0
                          ? ' text-danger'
                          : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.creditSummary.netWinLose}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Deposit</td>
                    <td
                      className={`text-dark text-right${
                        detail.creditSummary.deposit < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.creditSummary.deposit}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Withdraw</td>
                    <td
                      className={`text-dark text-right${
                        detail.creditSummary.withdraw < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.creditSummary.withdraw}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Commission</td>
                    <td
                      className={`text-dark text-right${
                        detail.creditSummary.commission < 0
                          ? ' text-danger'
                          : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.creditSummary.winCommission}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Balance</td>
                    <td
                      className={`text-dark text-right${
                        detail.creditSummary.balance < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.creditSummary.balance}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Given Credit</td>
                    <td
                      className={`text-dark text-right${
                        detail.credit < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.credit}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Available Credit</td>
                    <td
                      className={`text-dark text-right${
                        detail.availableBalance < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.availableBalance}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="table-responsive-sm col-md-6">
              <table className="table table-sm table-striped table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    <th scope="col" colSpan={7}>
                      Share Rate
                    </th>
                  </tr>
                  <tr>
                    <th scope="col" rowSpan={2}>
                      Sport
                    </th>
                    <th scope="col" colSpan={2}>
                      Live
                    </th>
                    <th scope="col" colSpan={2}>
                      Today
                    </th>
                    <th scope="col" colSpan={2}>
                      Early
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">Share</th>
                    <th scope="col">Commission</th>
                    <th scope="col">Share</th>
                    <th scope="col">Commission</th>
                    <th scope="col">Share</th>
                    <th scope="col">Commission</th>
                  </tr>
                </thead>
                <tbody>
                  {detail.stakeSettings.map((info, index) => (
                    <tr key={index}>
                      {info.sportId ? (
                        <>
                          <td className="text-dark text-center">
                            {info.sportName}
                          </td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.liveStake}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />{' '}
                            %
                          </td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.liveComm}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />{' '}
                            %
                          </td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.todayStake}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />{' '}
                            %
                          </td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.todayComm}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />{' '}
                            %
                          </td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.earlyStake}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />{' '}
                            %
                          </td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.earlyComm}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />{' '}
                            %
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="text-dark text-center">Parlay</td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.parlayStake}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />{' '}
                            %
                          </td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.parlayComm}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />{' '}
                            %
                          </td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.parlayStake}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />{' '}
                            %
                          </td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.parlayComm}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />{' '}
                            %
                          </td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.parlayStake}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />{' '}
                            %
                          </td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.parlayComm}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />{' '}
                            %
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>

              <table className="table table-sm table-striped table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    <th scope="col" colSpan={3}>
                      Setting
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">Min</th>
                    <th scope="col">Max</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-dark text-center">Single</td>
                    <td
                      className={`text-dark text-right${
                        detail.setting.minSingle < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.setting.minSingle}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td
                      className={`text-dark text-right${
                        detail.setting.maxSingle < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.setting.maxSingle}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Parlay</td>
                    <td
                      className={`text-dark text-right${
                        detail.setting.minParlay < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.setting.minParlay}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td
                      className={`text-dark text-right${
                        detail.setting.maxParlay < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.setting.maxParlay}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Game Limit</td>
                    <td
                      className={`text-dark text-right${
                        detail.setting.minParlay < 0 ? ' text-danger' : ''
                      }`}
                    >
                      &nbsp;
                    </td>
                    <td
                      className={`text-dark text-right${
                        detail.setting.maxTotal < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.setting.maxTotal}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* bottom row */}

          <h6 className="text-body px-4">
            Operation Log (<Link to="/ownOpLog">Details</Link>)
          </h6>

          <div className="row px-3">
            <div className="col-12">
              {operationLog !== undefined ? (
                <table className="table table-sm table-striped table-bordered">
                  <thead className="text-center thead-dark">
                    <tr>
                      <th>No.</th>
                      <th>Time</th>
                      <th>Username</th>
                      <th>IP</th>
                      <th>Activity Type</th>
                      <th>Error Code</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {operationLog.content.map((op, index) => (
                      <tr key={op.pubId} className="text-center ">
                        <td className="text-secondary">{index + 1}</td>
                        <td className="text-secondary">
                          {moment(op.createdAt).format('DD MMM h:mm a')}
                        </td>
                        <td className="text-secondary">{op.username}</td>
                        <td className="text-secondary">{op.ip}</td>
                        <td className="text-secondary">{op.activityType}</td>
                        <td className="text-secondary">{op.errorCode}</td>
                        <td className="text-secondary">{op.remarks}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Dashboard;
