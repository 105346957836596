import React, { useRef, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import Edit from './Edit';
import Stake from './Stake';
import Setting from './Setting';
import Password from './Password';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  borderInactive: {
    backgroundColor: '#e1e1e1'
  },
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

// role is for the data received from the data and givenRole is for "agent , master etc. for functions"

const Table = ({
  User,
  role,
  givenRole,
  setRole,
  totalPage,
  sumCredit,
  sumBalance,
  handleRoleSearch,
}) => {
  const classes = useStyles();
  // modal for edit
  const [editModal, setEditModal] = useState(false);
  const [stakeModal, setStakeModal] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  // modal for password
  const [passwordModal, setPasswordModal] = useState(false);
  // for passing value inside edit function also in password modal
  const username = useRef('');
  // for passing user id in op log
  const memberId = useRef('');
  // handle Credit
  const handleCredit = () => {
    window.open(`/credit?id=${memberId.current}`, 'blank');
  };
  // handle operation log
  const handleOpLog = () => {
    window.open(`/operation?id=${memberId.current}`, 'blank');
  };
  console.log('edit = '+User.privUserEdit);

  return (
    <>
      <div className="table-responsive-sm mx-2">
        <table className="table table-sm table-hover table-bordered">
          <thead className="text-center thead-dark">
            <tr>
              <th>No.</th>
              <th scope="col">Name</th>
              <th scope="col">Login Id</th>
              <th scope="col">Currency</th>
              <th scope="col">Given Credit</th>
              <th scope="col">Cash Balance</th>
              <th scope="col">Last Login Time</th>
              <th scope="col">Create Time</th>
              <th scope="col">Status</th>
              <th scope="col">Function</th>
            </tr>
          </thead>
          <tbody>
            {role.map((info, index) => (
              <tr
                className={`${
                  info.status === true ? '' : classes.borderInactive
                }`}
                key={index}
              >
                <td className="text-dark text-center">{index + 1}</td>
                <td className="text-dark">{info.name}</td>
                <td className="text-dark">{info.username}</td>
                <td className="text-dark text-center">{info.currency}</td>
                <td className="text-dark text-right">
                  <CurrencyFormat
                    value={info.credit}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </td>
                <td className="text-dark text-right">
                  <CurrencyFormat
                    value={info.availableBalance}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </td>
                <td className="text-dark text-center">
                  {info.lastLogin === null ? (
                    '-'
                  ) : (
                    <>{moment(info.lastLogin).format('DD/MM/YYYY HH:mm')} </>
                  )}
                </td>
                <td className="text-dark text-center">
                  {moment(info.createdAt).format('DD/MM/YYYY HH:mm')}
                </td>
                {info.status === true ? (
                  <td className="text-dark text-center">Active</td>
                ) : (
                  <td className="text-danger text-center">InActive</td>
                )}
                <td className="text-primary">
                  { User.privUser == null || (User.privUser === true && User.privUserEdit === true) ? (
                    <>
                      <button
                        className="btn btn-link text-primary p-0 "
                        onClick={() => {
                          memberId.current = info.id;
                          username.current = info.username;
                          setEditModal(true);
                        }}
                      >
                        Edit
                      </button>
                      /
                    </> ) : null}
                  { User.privUser == null || (User.privUser === true && User.privUserShare === true) ? (
                    <>
                      <button
                        className="btn btn-link text-primary p-0 "
                        onClick={() => {
                          memberId.current = info.id;
                          username.current = info.username;
                          setStakeModal(true);
                        }}
                      >
                        Edit %
                      </button>
                      /
                    </>
                  ) : null }
                  { User.privUser == null || (User.privUser === true && User.privUserSetting === true) ? (
                    <>
                      <button
                        className="btn btn-sm btn-link text-primary p-0"
                        onClick={() => {
                          memberId.current = info.id;
                          username.current = info.username;
                          setSettingModal(true);
                        }}
                      >
                        Setting
                      </button>
                      /
                    </>
                  ) : null }
                  <button
                    className="btn btn-sm btn-link text-primary p-0"
                    onClick={() => {
                      memberId.current = info.id;
                      handleCredit();
                    }}
                  >
                    Credit Log
                  </button>
                  /
                  <button
                    className="btn btn-sm btn-link text-primary p-0"
                    onClick={() => {
                      memberId.current = info.id;
                      handleOpLog();
                    }}
                  >
                    Operation Log
                  </button>
                  { User.privUser == null || (User.privUser === true && User.privUserPassword === true) ? (
                    <>
                      /
                      <button
                        className="btn btn-sm btn-link text-primary p-0"
                        onClick={() => {
                          username.current = info.username;
                          setPasswordModal(true);
                        }}
                      >
                        Password
                      </button>
                    </>
                  ) : null }
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="text-dark text-center" colSpan="4">Total</td>
              <td className={`text-right`}><CurrencyFormat value={sumCredit} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
              <td className={`text-right`}><CurrencyFormat value={sumBalance} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
              <td colSpan="4"></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <Pagination
        count={totalPage}
        onChange={handleRoleSearch}
        className={classes.pagination}
      />

      {/* edit modal */}
      <Edit
        setRole={setRole}
        id={memberId.current}
        editModal={editModal}
        setEditModal={setEditModal}
        username={username.current}
        givenRole={givenRole}
      />
      {/* stakeModal */}
      <Stake
        id={memberId.current}
        stakeModal={stakeModal}
        setStakeModal={setStakeModal}
        username={username.current}
      />
      {/* settingModal */}
      <Setting
        setSettingModal={setSettingModal}
        settingModal={settingModal}
        id={memberId.current}
        username={username.current}
      />
      {/* password modal */}
      <Password
        passwordModal={passwordModal}
        setPasswordModal={setPasswordModal}
        username={username.current}
      ></Password>
    </>
  );
};

export default Table;
