import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import Navbar from '../../components/Navbar';
import BetList from '../../components/BetList';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const Win = ({User}) => {
  const [date, setDate] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [running, setRunning] = useState(null);
  const [game, setGame] = useState(null);
  const [playerWager, setPlayerWager] = useState(0);
  const [playerWinning, setPlayerWinning] = useState(0);
  const [bets, setBets] = useState([]);
  const [games, setGames] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const history = useHistory();
  
  const calculateBet = (report) => {
    let wager = 0;
    let winning = 0;
    for (var i = 0; i < report.length; i++) {
      wager += report[i].amount;
      if (report[i].winning) winning+= report[i].winning;
    }
    setPlayerWager(wager);
    setPlayerWinning(winning);
  }

  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    if (value == null) value = 1;
    let betUrl = 'agent/wins';
    axiosInstance
      .get(betUrl, {
        params: {
          startDate: date ? moment(date).format('DD-MM-YYYY') : null,
          running: running ? running : null,
          game: game? game : null,
          pageNo: isNaN(value) ? null : value - 1,
        },
      })
      .then((res) => {
        setBets(res.data.content);
        calculateBet(res.data.content);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  }

  useEffect(() => {
    handleChange(null);
  }, [date, running, game]);

  useEffect(() => {
    if (date == null){
      axiosInstance
        .get('public/today')
        .then((res) => {
          let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
          today.setDate(today.getDate());
          if (date == null) setDate(today);
          let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
          minSearchDate.setDate(minSearchDate.getDate() - 90);
          setSearchDate(minSearchDate);
      });
    }
    if (games.length === 0) {
      axiosInstance
        .get('agent/gameTypes')
        .then((res) => {
          setGames(res.data);
        });
    }
  }, [history.location]);

  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" id="form">
          Date:
          <DatePicker
            selected={date ? date : new Date()}
            minDate={searchDate}
            onChange={(dt) => setDate(dt)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
          <label htmlFor="type" className="mx-2 my-0">
            Type :
          </label>
          <select
            name=""
            id="type"
            style={{ minWidth: '100px' }}
            className="py-1 "
            onChange={(gt) => setGame(gt.target.value)}
          >
            <option value="">All</option>
              {games.map((gm) => (
                <option value={gm.key}>{gm.val}</option>
              ))}
          </select>
          <label htmlFor="running" className="mx-2 my-0">
            Running :
          </label>
          <select
            name=""
            id="running"
            style={{ minWidth: '100px' }}
            onChange={(rn) => setRunning(rn.target.value)}
            className="py-1 "
          >
            <option value="">All</option>
            <option value="true">Running</option>
            <option value="false">Non-Running</option>
          </select>
        </form>
      </div>
      {/* table */}
      <div className="table-responsive-sm mx-2">
        <BetList bets={bets} handleChange={handleChange} playerWager={playerWager} playerWinning={playerWinning} totalPage={totalPage} />
      </div>
    </>
  );
};

export default Win;
