import React, { useEffect, useState } from 'react';
import axiosInstance from '../../config';
import Table from '../../components/Table';
import Create from '../../components/Create';
import Navbar from '../../components/Navbar';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const Agent = ({ User, username }) => {
  const classes = useStyles();
  // modal for creating agent
  const [open, setOpen] = useState(false);
  // data for table in agent
  const [agent, setAgent] = useState(null);
  // currencies
  const [currency, setCurrency] = useState([]);
  // sort by
  const [Sort, setSort] = useState([]);
  // array for options
  const number = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const selectedRole = 'Agent';
  // pagination
  const [totalPage, setTotalPage] = useState(null);
  const [sumCredit, setSumCredit] = useState(0);
  const [sumBalance, setSumBalance] = useState(0);

  //  handle search
  const handleRoleSearch = (e, page) => {
    if (e != null) e.preventDefault();
    const memberForm = document.getElementById('searchform');
    const searchName = memberForm['name'].value;
    const searchUser = memberForm['login'].value;
    const searchStatus = memberForm['status'].value;
    const sort = memberForm['sort'].value;
    const asc = memberForm['asc'].value;
    axiosInstance
      .get('agent/agents', {
        params: {
          searchName: searchName !== '' ? searchName : null,
          searchUser: searchUser !== '' ? searchUser : null,
          searchStatus: searchStatus !== '' ? searchStatus : null,
          sort: sort !== '' ? sort : null,
          asc: asc !== '' ? asc : null,
          type: selectedRole.toUpperCase(),
          pageNo: isNaN(page) ? null : page - 1,
        },
      })
      .then((res) => {
        console.log(res.data);
        setTotalPage(res.data.totalPages);
        setAgent(res.data.content);
        sumCredBalance(res.data.content);
      })
      .catch((err) => console.log(err));
  };

  const sumCredBalance = (agents) => {
    let sumC = 0;
    let sumB = 0;
    for (let i = 0; i < agents.length; i++) {
      sumC += agents[i].credit;
      sumB += agents[i].availableBalance;
    }
    setSumCredit(sumC);
    setSumBalance(sumB);
  };

  useEffect(() => {
    // getting agent list
    if (selectedRole == null) return;
    axiosInstance
      .get('/agent/agents?type=' + selectedRole.toUpperCase())
      .then((res) => {
        console.log(res.data);
        setTotalPage(res.data.totalPages);
        setAgent(res.data.content);
        sumCredBalance(res.data.content);
      })
      .catch((err) => console.log(err));
    // currencies for new addition
    axiosInstance
      .get('/agent/currencies')
      .then((res) => {
        console.log(res.data);
        setCurrency(res.data);
      })
      .catch((err) => console.log(err));
    // sort by option
    axiosInstance
      .get('/agent/sort ')
      .then((res) => {
        console.log(res.data);
        setSort(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navbar username={username} User={User} />

      {agent !== null ? (
        <>
          <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
            <form
              action=""
              className="form-inline"
              id="searchform"
              onSubmit={handleRoleSearch}
            >
              <label
                htmlFor="name"
                className="text-body font-weight-bold mx-2 ml-0"
              >
                Name{' '}
              </label>
              <input
                type="text"
                id="name"
                className="form-control form-control-xs"
              />
              <label
                htmlFor="login"
                className="text-body font-weight-bold mx-2"
              >
                Login Id{' '}
              </label>
              <input
                type="text"
                id="login"
                className="form-control form-control-xs"
              />
              <label
                htmlFor="status"
                className="text-body font-weight-bold mx-2"
              >
                Status{' '}
              </label>
              <select
                name=""
                id="status"
                className="form-control form-control-xs"
              >
                {/* <select name="" id="status" className="form-control form-control-xs"> */}
                <option value="">All</option>
                <option value={true}>Active</option>
                <option value={false}>InActive</option>
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label htmlFor="sort" className="text-body font-weight-bold mx-2">
                Sort by{' '}
              </label>
              <select
                name=""
                id="sort"
                className="form-control form-control-xs"
              >
                {/* <option value="">None</option> */}
                {Sort.map((srt, index) => (
                  <option value={srt.key} key={index}>
                    {srt.val}
                  </option>
                ))}
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <select
                name=""
                id="asc"
                className="form-control form-control-xs mx-2"
              >
                {/* <option value="">None</option> */}
                <option value={1}>Asc</option>
                <option value={0}>Desc</option>
              </select>
              <input
                type="submit"
                value="Search"
                className="btn btn-sm btn-primary px-3 py-1 "
              />
              &nbsp;&nbsp;&nbsp;
            </form>
            { User.privUser == null || (User.privUser === true && User.privUserAgent === true) ? (
              <button
                className="btn btn-sm btn-primary px-3 py-1 "
                onClick={() => {
                  setOpen(true);
                }}
              >
                Create {selectedRole}
              </button>
            ) : null }
          </div>
          <Table
            User={User}
            role={agent}
            givenRole={selectedRole}
            setRole={setAgent}
            totalPage={totalPage}
            sumCredit={sumCredit}
            sumBalance={sumBalance}
            handleRoleSearch={handleRoleSearch}
          />
          {/* modal for create agent */}
          <Create
            type={selectedRole}
            open={open}
            setOpen={setOpen}
            number={number}
            setAgent={setAgent}
            currency={currency}
          />
        </>
      ) : null}
    </>
  );
};

export default Agent;
