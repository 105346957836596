import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Navbar from '../../components/Navbar';
import moment from 'moment';
import Cash from '../../components/Cash';
import DepWd from '../../components/DepWd';

const useStyles = makeStyles((theme) => ({
  border: {
    '& td': {
      borderBottom: '1px solid black !important',
    },
  },
  borderInactive: {
    backgroundColor: '#e1e1e1',
    '& td': {
      borderBottom: '1px solid black !important',
    },
  },
  reject: {
    textDecoration: 'line-through',
  },
  compName: {
    color: '#0E2336',
    fontWeight: 'bold',
  },
  teams: {
    color: '#323030',
  },
  strong: {
    color: '#DC252F',
  },
  handiCap: {
    color: '#1452CC ',
    fontWeight: 'bold',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const WinLose = ({User}) => {
  const classes = useStyles();
  const [username, setUsername] = useState(null);
  const [cashModal, setCashModal] = useState(false);
  const [depositModal, setDepositModal] = useState(false);
  const [depositType, setDepositType] = useState(null);
  const [cashType, setCashType] = useState(false);
  const [selected, setSelected] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [reports, setReports] = useState([]);
  const [detailUsername, setDetailUsername] = useState(null);
  const [details, setDetails] = useState([]);
  const [totalWl, setTotalWl] = useState(0);
  const [totalComm, setTotalComm] = useState(0);
  const [totalDep, setTotalDep] = useState(0);
  const [totalWd, setTotalWd] = useState(0);
  const history = useHistory();
  const backToParent = () => {
    if (detailUsername != null) {
      setDetails([]);
      setDetailUsername(null);
    }
    else if (
      username != null &&
      username.length > 0 &&
      username !== localStorage.getItem('me')
    ) {
      if (username.length > 8) setUsername(username.substring(0, 8));
      else setUsername(username.substring(0, username.length - 2));
    }
  };

  const handleCredit = (usr, usrname) => {
    if (usrname.length > 8){
      window.open(`/creditm?id=${usr}${startDate != null ? '&startDate='+moment(startDate).format('DD-MM-YYYY'):''}${endDate != null ? '&endDate='+moment(endDate).format('DD-MM-YYYY'):''}`, 'blank');
    }
    else {
      window.open(`/credit?id=${usr}${startDate != null ? '&startDate='+moment(startDate).format('DD-MM-YYYY'):''}${endDate != null ? '&endDate='+moment(endDate).format('DD-MM-YYYY'):''}`, 'blank');
    }

  };

  const getToday = (minus) => {
    axiosInstance
      .get('public/todayCal')
      .then((res) => {
        setStartDate(null);
        setEndDate(null);
        let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        today.setDate(today.getDate() - minus);
        setStartDate(today);
        setEndDate(today);
        setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
        let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
    });
  }

  const getWeek = (minus) => {
    axiosInstance
      .get('public/todayCal')
      .then((res) => {
        setStartDate(null);
        setEndDate(null);
        let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        let today2 = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        let diff = today.getDay() - 1;
        if (diff < 0) diff += 7;
        today.setDate(today.getDate() - diff);
        if (minus > 0){
          today.setTime(today.getTime() - minus * 7 * 24*60*60*1000);
          today2.setTime(today.getTime() + 6 * 24*60*60*1000);
        }
        setStartDate(today);
        setEndDate(today2);
        setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
        let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
    });
  }

  const getMonth = (minus) => {
    axiosInstance
      .get('public/todayCal')
      .then((res) => {
        setStartDate(null);
        setEndDate(null);
        let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        let today2 = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        if (minus > 0) {
          today.setMonth(today.getMonth() - minus);
          if (minus > 1) today2.setMonth(today2.getMonth() - minus + 1);
          today2.setDate(minus - 1);
        }
        today.setDate(1);
        setStartDate(today);
        setEndDate(today2);
        setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
        let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
    });
  }

  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    if (username != null && username.length > 8) return;
    if (detailUsername != null) {
      setReports([]);
      axiosInstance
      .get('agent/balanceReport/winLoseDetail', {
        params: {
          startDate: startDate? moment(startDate).format('DD-MM-YYYY'): null,
          endDate: endDate? moment(endDate).format('DD-MM-YYYY'): null,
          username: detailUsername,
        },
      })
      .then((res) => {
        setDetails(res.data);
        let sumWl = 0, sumComm = 0, sumDep = 0, sumWd = 0;
        for (let i = 0; i < res.data.length; i++){
          sumWl += res.data[i].netWinLose;
          sumComm += res.data[i].winCommission;
          sumDep += res.data[i].deposit;
          sumWd += res.data[i].withdraw;
        }
        setTotalWl(sumWl);
        setTotalComm(sumComm);
        setTotalDep(sumDep);
        setTotalWd(sumWd);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
    }
    else {
      axiosInstance
      .get('agent/balanceReport/winLose', {
        params: {
          startDate: startDate? moment(startDate).format('DD-MM-YYYY'):null,
          endDate: endDate? moment(endDate).format('DD-MM-YYYY'): null,
          agentUsername: username? username: null
        },
      })
      .then((res) => {
        setReports(res.data.content);
        let sumWl = 0, sumComm = 0, sumDep = 0, sumWd = 0;
        for (let i = 0; i < res.data.content.length; i++){
          sumWl += res.data.content[i].netWinLose;
          sumComm += res.data.content[i].winCommission;
          sumDep += res.data.content[i].deposit;
          sumWd += res.data.content[i].withdraw;
        }
        setTotalWl(sumWl);
        setTotalComm(sumComm);
        setTotalDep(sumDep);
        setTotalWd(sumWd);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
    }
    
  };

  useEffect(() => {
    if (startDate != null && endDate != null) handleChange(null);
  }, [username, startDate, endDate, detailUsername]);

  useEffect(() => {
    axiosInstance
        .get('public/todayCal')
        .then((res) => {
          if (startDate == null)  setStartDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
          if (endDate == null)  setEndDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
          setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
          let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
          minSearchDate.setDate(minSearchDate.getDate() - 90);
          setSearchDate(minSearchDate);
      });
  }, [history.location]);

  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={handleChange} id="form">
          Start Date:
          <DatePicker
            selected={startDate ? startDate : new Date()}
            minDate={searchDate}
            onChange={(date) => setStartDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
          End Date:
          <DatePicker
            selected={endDate ? endDate : new Date()}
            onChange={(date) => setEndDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
            minDate={startDate}
            maxDate={maxDate}
          />
          <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getToday(0);
              }}
            >
              Today
          </button>{' '}
          <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getToday(1);
              }}
            >
              Yesterday
            </button>{' '}
          <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getWeek(0);
              }}
            >
              This Week
          </button>{' '}
          <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getWeek(1);
              }}
            >
              Last Week
            </button>{' '}
            <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getMonth(0);
              }}
            >
              This Month
            </button>{' '}
            <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getMonth(1);
              }}
            >
              Last Month
            </button>{' '}
        </form>
      </div>
      {detailUsername != null || (username != null &&
          username.length > 0 &&
          username !== localStorage.getItem('me')) ? (
            <button
              className="btn btn-sm btn-primary px-1 py-1 my-2 mx-2"
              onClick={() => {
                backToParent();
              }}
            >
              Return
            </button>
          ) : null}
      {reports.length > 0 ? (
        <div className="table-responsive-sm mx-2">
              <table className="table table-sm table-hover table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Login ID</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Beginning</th>
                    <th scope="col">Net W/L</th>
                    <th scope="col">Commission</th>
                    <th scope="col">Deposit</th>
                    <th scope="col">Withdraw</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Status</th>
                    <th scope="col">Function</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.map((info, index) => (
                    <tr
                      className={`${
                        info.status === true ? classes.border : classes.borderInactive
                      }`}
                      key={index}
                    >
                    {/* <tr key={index}> */}
                      <td className="text-dark text-left">{info.name}</td>
                      <td className="text-dark text-left">
                        {info.username.length > 8 ? info.username: (
                          <button
                            className="btn btn-link text-primary p-0 "
                            onClick={() => {
                              setUsername(info.username);
                            }}
                          >
                            {info.username}
                          </button>
                        )}
                      </td>
                      <td className="text-dark text-center">{info.currency}</td>
                      <td className={`${info.beginning < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.beginning? info.beginning: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                      <td className={`${info.netWinLose < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.netWinLose? info.netWinLose: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                      <td className={`${info.winCommission< 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.winCommission? info.winCommission: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                      <td className={`${info.deposit < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.deposit? info.deposit: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                      <td className={`${info.withdraw < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.withdraw? info.withdraw: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                      <td className={`${info.balance < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.balance? info.balance: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                      {info.status === true ? (
                        <td className="text-dark text-center">Active</td>
                      ) : (
                        <td className="text-danger text-center">InActive</td>
                      )}
                      <td>
                        {info.username.length <= 8 ? (
                          <>
                            <button
                              className="btn btn-sm btn-link text-primary p-0"
                              onClick={() => {
                                setDetailUsername(info.username);
                                // viewDetail(info.username);
                              }}
                            >
                              Detail
                            </button>&nbsp;|&nbsp;
                            {User.privBalReport == null || (User.privBalReport === true && User.privBalReportDw === true) ? (
                            <>
                              <button
                                className="btn btn-sm btn-link text-primary p-0"
                                onClick={() => {
                                  setSelected(info.username);
                                  setCashType('Cash In');
                                  setCashModal(true);
                                }}
                              >
                                Deposit
                              </button>&nbsp;|&nbsp;
                              <button
                                className="btn btn-sm btn-link text-primary p-0"
                                onClick={() => {
                                  setSelected(info.username);
                                  setCashType('Cash Out');
                                  setCashModal(true);
                                }}
                              >
                                Withdraw
                              </button>&nbsp;|&nbsp;
                            </>
                            ) : null}
                          </>
                        ): User.privBalReport == null || (User.privBalReport === true && User.privBalReportDw === true) ?  (
                          <>
                            <button
                              className="btn btn-sm btn-link text-primary p-0"
                              onClick={() => {
                                setSelected(info.username);
                                setDepositType('DEPOSIT');
                                setDepositModal(true);
                              }}
                            >
                              Deposit
                            </button>&nbsp;|&nbsp;
                            <button
                              className="btn btn-sm btn-link text-primary p-0"
                              onClick={() => {
                                setSelected(info.username);
                                setDepositType('WITHDRAW');
                                setDepositModal(true);
                              }}
                            >
                              Withdraw
                            </button>&nbsp;|&nbsp;
                          </>
                        ) : null}
                        <button
                          className="btn btn-sm btn-link text-primary p-0"
                          onClick={() => {
                            handleCredit(info.id, info.username);
                          }}
                        >
                          Cash log
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="text-dark text-center" colSpan="4">Total</td>
                    <td className={`${totalWl < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalWl} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                    <td className={`${totalComm < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalComm} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                    <td className={`${totalDep < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalDep} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                    <td className={`${totalWd < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalWd} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                    <td className="text-dark text-center" colSpan="3"></td>
                  </tr>
                </tfoot>
              </table>

        </div>
      ) : null}
      {details.length > 0 ? (
        <div className="table-responsive-sm mx-2">
          {detailUsername} Details
          <table className="table table-sm table-hover table-bordered">
            <thead className="text-center thead-dark">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Day</th>
                <th scope="col">Currency</th>
                <th scope="col">Beginning</th>
                <th scope="col">Net W/L</th>
                <th scope="col">Commission</th>
                <th scope="col">Deposit</th>
                <th scope="col">Withdraw</th>
                <th scope="col">Balance</th>
              </tr>
            </thead>
            <tbody>
              {details.map((info, index) => (
                <tr key={index}>
                  <td className="text-dark text-center">{moment(info.reportDate).format('DD-MM-YYYY')}</td>
                  <td className="text-dark text-center">{moment(info.reportDate).format('ddd')}</td>
                  <td className="text-dark text-center">{info.currency}</td>
                  <td className={`${info.beginning < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.beginning? info.beginning: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className={`${info.netWinLose < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.netWinLose? info.netWinLose: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className={`${info.winCommission< 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.winCommission? info.winCommission: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className={`${info.deposit < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.deposit? info.deposit: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className={`${info.withdraw < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.withdraw? info.withdraw: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className={`${info.balance < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.balance? info.balance: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="text-dark text-center" colSpan="4">Total</td>
                <td className={`${totalWl < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalWl} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className={`${totalComm < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalComm} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className={`${totalDep < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalDep} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className={`${totalWd < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalWd} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      ) : null}
      <Cash
        type={cashType}
        cashModal={cashModal}
        setCashModal={setCashModal}
        username={selected}
        refresh={handleChange}
      />
      <DepWd
        type={depositType}
        depositModal={depositModal}
        setDepositModal={setDepositModal}
        username={selected}
        refresh={handleChange}
      />
    </>
  );
};

export default WinLose;
