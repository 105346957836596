import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import axiosInstance from '../config';
import ReportNavbar from './navbars/ReportNavbar';
import UserNavbar from './navbars/UserNavbar';
import BalNavbar from './navbars/BalNavbar';
import Password from './Password';

const useStyles = makeStyles((theme) => ({
  dark: {
    backgroundColor: '#e7e7e7 !important',
  },
}));

const Navbar = ({ username, User }) => {
  const role = localStorage.getItem('role');
  const classes = useStyles();
  // toggling between navbars
  const [isUserNavbar, setIsUserNavbar] = useState(true);
  const [isReportNavbar, setIsReportNavbar] = useState(false);
  // reset password modal
  const [reset, setReset] = useState(false);
  // handle logout
  const handleLogout = () => {
    axiosInstance
      .post('/token/invalidate')
      .then((res) => { 
        console.log(res);
        localStorage.clear();
      })
      .catch((err) => console.log(err));
  };

  // handle user navbar
  const handleUser = () => {
    setIsUserNavbar((e) => !e);
    setIsReportNavbar(false);

    // make rest of the navbars false
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-light  py-0 border-bottom ${classes.dark}`}
      >
        <a className="navbar-brand py-0 " href="/message">
          AGENT
        </a>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav position-relative">
            <Link
              className={`nav-link text-dark${
                window.location.pathname === '/dashboard' ? ' active' : ''
              }`}
              to="/dashboard"
            >
              Dashboard
            </Link>
            <Link
              className={`nav-link text-dark${
                window.location.pathname === '/message' ? ' active' : ''
              }`}
              to="/message"
            >
              Messages
            </Link>

            { User.privUser !== false? (
              <Link
                className={`nav-link text-dark${
                  window.location.pathname === '/member' ||
                  window.location.pathname === '/agent' ||
                  window.location.pathname === '/master' ||
                  window.location.pathname === '/senior' ||
                  window.location.pathname === '/vendor'
                    ? ' active'
                    : ''
                }`}
                to="/member"
                onClick={handleUser}
              >
                User
              </Link>
            ) : null}

            { User.privReport !== false? (
              <Link
                className={`nav-link text-dark${
                  window.location.pathname === '/wl' ||
                  window.location.pathname === '/wlDetail' ||
                  window.location.pathname === '/wlType' ||
                  window.location.pathname === '/out' ||
                  window.location.pathname === '/outDetail' ||
                  window.location.pathname === '/outType' ||
                  window.location.pathname === '/cancel' ||
                  window.location.pathname === '/outstanding' ||
                  window.location.pathname === '/gamecategory' ||
                  window.location.pathname === '/bigwinner' ||
                  window.location.pathname === '/sharerate' ||
                  window.location.pathname === '/win'
                    ? ' active'
                    : ''
                }`}
                to="/wl"
              >
                Report
              </Link>
            ): null}
            { User.privBalReport !== false? (
              <Link
                className={`nav-link text-dark${
                  window.location.pathname === '/winlose'
                    ? ' active'
                    : ''
                }`}
                to="/winlose"
              >
                Bal Report
              </Link>
            ): null}

            <Link
              className="nav-link text-dark"
              to="#"
              onClick={() => {
                setReset(true);
              }}
            >
              Change Password
            </Link>
          </div>
          <div className={`d-flex ml-auto align-items-center`}>
            {localStorage.getItem('me') !== undefined ? (
              <span className="active">
                Hi, <strong>{localStorage.getItem('me')}</strong>
              </span>
            ) : null}
            <Link
              to="/"
              onClick={handleLogout}
              className="nav-link text-dark font-weight-bold active"
            >
              Logout
            </Link>
          </div>
        </div>
      </nav>
      {/* navbars for different menus */}
      <UserNavbar
        User={User}
        role={role}
        isUserNavbar={
          window.location.pathname === '/sub' ||
          window.location.pathname === '/member' ||
          window.location.pathname === '/agent' ||
          window.location.pathname === '/master' ||
          window.location.pathname === '/senior' ||
          window.location.pathname === '/vendor'
        }
      />
      {/* report navbar */}

      <ReportNavbar
        role={role}
        isReportNavbar={
          window.location.pathname === '/wl' ||
          window.location.pathname === '/wlDetail' ||
          window.location.pathname === '/wlType' ||
          window.location.pathname === '/out' ||
          window.location.pathname === '/outDetail' ||
          window.location.pathname === '/outType' ||
          window.location.pathname === '/cancel' ||
          window.location.pathname === '/outstanding' ||
          window.location.pathname === '/gamecategory' ||
          window.location.pathname === '/sharerate' ||
          window.location.pathname === '/win' ||
          // window.location.pathname === '/winlose' ||
          window.location.pathname === '/bigwinner'
        }
      />

      <BalNavbar
        isBalNavbar={
          window.location.pathname === '/winlose' 
        }
      />

      {/* reset password modal */}
      {localStorage.getItem('me') !== undefined ? (
        <Password
          passwordModal={reset}
          setPasswordModal={setReset}
          username={localStorage.getItem('me')}
        />
      ) : null}
    </>
  );
};

export default Navbar;
