import React, {useEffect, useState} from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const Create = ({
  open,
  setOpen,
  type,
  number,
  setAgent,
  currency,
}) => {
  const classes = useStyles();
  const [copy, setCopy] = useState([]);
  const [ids, setId] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (open) {
      // getting prefix for popup modal
      if (type !== 'Vendor') {
        axiosInstance
        .get('agent/uplines', {
          params: {
            type: type.toUpperCase(),
          },
        })
        .then((res) => {
          console.log(res);
          setId(res.data);
          // getting copy from
        })
        .catch((err) => console.log(err));
      }
      axiosInstance
        .get('agent/copies', {
          params: {
            // agent: res.data[0].val,
            type: type.toUpperCase(),
          },
        })
        .then((res) => {
          console.log(res.data);
          setCopy(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [open]);

  const handleCreate = (e) => {
    e.preventDefault();
    const form = document.getElementById('create-form');
    const prefix = form['create-prefix'].value;
    const no1 = form['no1'].value;
    const no2 = form['no2'].value;
    const username = `${no1}${no2}`;
    const name = form['create-name'].value;
    const password = form['create-pass'].value;
    const passwordCfm = form['confirm-pass'].value;
    const myPassword = form['agentPass'].value;
    const credit = form['create-credit'].value;
    const copyDd = form['create-copy'].value;
    const status = form['create-status'].value;
    const mobile = form['create-mobile'].value;
    const remarks = form['create-remark'].value;

    if (password !== passwordCfm){
      toast.error('Password and confirm password does not match!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        });
      return;
    }

    const requestBody = {
      prefix: prefix,
      username: username,
      name: name,
      password: password,
      mobile: mobile,
      copy: copyDd,
      status: status,
      remarks: remarks,
      credit: credit,
      myPassword: myPassword,
      type: type.toUpperCase(),
    };

    axiosInstance
      .post('agent/agents', qs.stringify(requestBody))
      .then((res) => {
        console.log(res.data);
        // refreshing the agent and closing the modal
        axiosInstance
          .get('/agent/agents?type='+type.toUpperCase())
          .then((res) => {
            toast.success('Success!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              });
            setAgent(res.data.content);
          })
          .catch((err) => console.log(err));    
        setOpen(false);
      })
      .catch((err) => {
        if (err.response && err.response.data.message === 'AG200'){
          toast.error('Invalid password!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            });
        }
        else if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  // closing the modal
  const handleClose = () => {
    setOpen(false);
  };

  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form onSubmit={handleCreate} id="create-form">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className="bg-primary text-light font-weight-bold"
                >
                  New {type}
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              <tr>
                <td className="text-body font-weight-bold ">
                  Your Password
                </td>
                <td>
                  <input type="password" id="agentPass" required />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Login Id</td>
                <td>
                  { type !== 'Vendor' ? (
                    <select
                      name=""
                      id="create-prefix"
                    >
                      {ids.map((id, index) => (
                        <option value={id.val} key={index}>
                          {id.val}
                        </option>
                      ))}
                    </select>
                  ):(
                    <input type="hidden" id="create-prefix" value=""/>
                  )}
                  
                  <select name="" className="mx-2" id="no1">
                    {number.map((option, index) => (
                      <option value={option} key={index}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select name="" className="mx-2" id="no2">
                    {number.map((option, index) => (
                      <option value={option} key={index}>
                        {option}
                      </option>
                    ))}
                    <option value=""></option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Name</td>
                <td>
                  <input type="text" id="create-name" required />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Password</td>
                <td>
                  <input type="password" id="create-pass" required />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">
                  Confirm Password
                </td>
                <td>
                  <input type="password" id="confirm-pass" required />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Status</td>
                <td>
                  <select name="" id="create-status">
                    <option value={1}>Active</option>
                    <option value={2}>InActive</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Currency</td>
                <td>
                  <select name="" id="create-currency">
                    {currency.map((currency, i) => (
                      <option value="" key={i}>
                        {currency.val}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">
                  Given Credit
                </td>
                <td>
                  <input
                    type="create-text"
                    placeholder="0.00"
                    id="create-credit"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Phone</td>
                <td>
                  <input type="tel" id="create-mobile" />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Remark</td>
                <td>
                  <textarea
                    name=""
                    id="create-remark"
                    cols="30"
                    rows="3"
                  ></textarea>
                </td>
              </tr>

              <tr>
                <td className="text-body font-weight-bold ">Copy From</td>
                <td>
                  <select name="" id="create-copy">
                    <option value="">None</option>
                    {copy.map((cpy, index) => (
                      <option value={cpy.val} key={index}>
                        {cpy.val}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-sm btn-primary mx-3 px-3 py-1"
                    />

                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </Modal>
  );
};

export default Create;