import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const DepWd = ({
  depositModal,
  setDepositModal,
  username,
  type,
  refresh,
}) => {
  const classes = useStyles();
  const handleClose = () => {
    setDepositModal(false);
  };
  const [selected, setSelected] = useState(null);
  const [validPwd, setValidPwd] = useState(true);

  // handle Deposit
  const handleDeposit = (e) => {
    setValidPwd(true);
    e.preventDefault();
    let requestBody = {
      username: username,
      type: type, // WITHDRAW
      amount: e.target.deposit.value,
      password: document.getElementById('deposit-pwd').value,
      remarks: document.getElementById('deposit-remark').value,
    };
    if (type == 'WITHDRAW'){
      requestBody = {
        username: username,
        type: type, // WITHDRAW
        amount: e.target.deposit.value,
        password: document.getElementById('deposit-pwd').value,
        remarks: document.getElementById('deposit-remark').value,
        otp: document.getElementById('otp').value,
      };
    }
    axiosInstance
      .post('/agent/txns', qs.stringify(requestBody))
      .then((res) => {
        if (res) {
          console.log(res);
          toast.success('Success!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setDepositModal(false);
          refresh(null);
        }
        // update members
      });
  };

  useEffect(() => {
    if (username != null && depositModal === true) {
      axiosInstance
      .get('/agent/txns?username='+username+"&type="+type)
      .then((res) => {
        console.log(res);
        setSelected(res.data);
      })
      .catch((err) => console.log(err));
    }
  }, [username, depositModal]);

  return (
    <>
      <Modal
        open={depositModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalStyle}
      >
        <div className={classes.Modal}>
          <form action="" onSubmit={handleDeposit}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    colSpan="2"
                    className="bg-primary text-light font-weight-bold"
                  >
                    {type}
                  </th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                <tr>
                  <td className="text-body font-weight-bold">Your Password</td>
                  <td>
                    <input type="password" id="deposit-pwd" required />
                    {validPwd === false ? (
                      <font color="red">Invalid password</font>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td className="text-body font-weight-bold">Login Id</td>
                  <td>{selected ? selected.username : null}</td>
                </tr>
                <tr>
                  <td className="text-body font-weight-bold">Name</td>
                  <td>{selected ? selected.name : null}</td>
                </tr>
                <tr>
                  <td className="text-body font-weight-bold">Balance</td>
                  <td><CurrencyFormat value={selected? selected.balance: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                </tr>
                <tr>
                  <td className="text-body font-weight-bold">Amount</td>
                  <td>
                    <input
                      type="number"
                      id="deposit"
                      min={0}
                      max={selected ? selected.limit : null}
                      required
                    />
                    {selected !== null ? (
                      <label htmlFor="deposit" className="ml-2">
                        {' '}
                        Max (<CurrencyFormat value={selected.limit? selected.limit: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/>)
                      </label>
                    ) : null}
                  </td>
                </tr>
                { type === 'WITHDRAW' ? (
                  <tr>
                    <td className="text-body font-weight-bold">OTP</td>
                    <td>
                      <input type="tel" id="otp" required />
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td className="text-body font-weight-bold ">Remark</td>
                  <td>
                    <textarea
                      name=""
                      id="deposit-remark"
                      cols="30"
                      rows="3"
                    ></textarea>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div className="d-flex justify-content-end">
                      <input
                        type="submit"
                        value="Submit"
                        className="btn btn-sm btn-primary mx-3 px-3 py-1"
                      />

                      <button
                        className="btn btn-sm btn-danger px-3 py-1"
                        onClick={() => {
                          setDepositModal(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default DepWd;
