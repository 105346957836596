import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from '../config';
import moment from 'moment';
import {useLocation} from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import BetInfo from '../pages/member/functions/BetInfo';

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const CreditLog = () => {
  const classes = useStyles();
  function useQuery(){
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const [username, setUsername] = useState(null);
  const [totalPage, setTotalPage] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [credit, setCredit] = useState(null);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);

  // modal for BetInfo
  const [betInfoModal, setBetInfoModal] = useState(false);
  const reference = useRef('');

  const handleForm = (e, value) => {
    if (e != null) e.preventDefault();
    axiosInstance
      .get(`/agent/agents/${query.get('id')}/creditLog`, {
        params: {
          startDate: moment(startDate).format('DD-MM-YYYY'),
          endDate: moment(endDate).format('DD-MM-YYYY'),
          pageNo: value - 1,
        },
      })
      .then((res) => {
        console.log(res.data);
        setCredit(res.data.content);
        setTotalPage(res.data.totalPages);
        let sumDeb = 0, sumCred = 0;
        for (let i = 0; i < res.data.content.length; i++){
          if (res.data.content[i].debit) sumDeb += res.data.content[i].debit;
          if (res.data.content[i].credit) sumCred += res.data.content[i].debit;
        }
        setTotalCredit(sumCred);
        setTotalDebit(sumDeb);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (username == null) {
      axiosInstance.get(`/agent/agents/${query.get('id')}`)
      .then((res) => {
        setUsername(res.data.username);
      })
      .catch((err) => console.log(err));
    }
    if (startDate == null && query.get("startDate") != null){
      console.log('set start date ');
      setStartDate(new Date(moment(query.get("startDate"),"DD-MM-YYYY")));
    }
    if (endDate == null && query.get("endDate") != null){
      console.log('set end date');
      setEndDate(new Date(moment(query.get("endDate"),"DD-MM-YYYY")));
    }
    handleForm(null, 1);
  }, [startDate, endDate]);

  return (
    <>
      {credit !== null ? (
        <>
          <p/><p/>
          <form action="" onSubmit={handleForm} id="form">
            <h6>Credit Log {username}</h6>
            <div className="text-dark d-flex align-items-center">
              <strong>Start Date:</strong>
              <DatePicker
                selected={startDate ? startDate: new Date()}
                onChange={(date) => setStartDate(date)}
                className="mx-2"
                dateFormat="d MMM yyyy"
              />
              <div>
                <strong>End Date:</strong>
                <DatePicker
                  selected={endDate ? endDate: new Date()}
                  onChange={(date) => setEndDate(date)}
                  className="mx-2"
                  dateFormat="d MMM yyyy"
                />
              </div>
            </div>
          </form>
          <p/>
          <table className="table table-sm table-striped table-bordered">
            <thead className="text-center thead-dark">
              <tr>
                <th>No.</th>
                <th>Time</th>
                <th>Currency</th>
                <th>Beginning</th>
                <th>Credit</th>
                <th>Debit</th>
                <th>End</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {credit.map((cred, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{moment(cred.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                  <td>{cred.currencyCode}</td>
                  <td className="text-right"><CurrencyFormat value={cred.beginning? cred.beginning: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className="text-right"><CurrencyFormat value={cred.credit? cred.credit: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className={`text-right${cred.debit && cred.debit > 0?' text-danger':''}`}><CurrencyFormat value={cred.debit? cred.debit: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className="text-right"><CurrencyFormat value={cred.end? cred.end: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td>
                    {cred.transactionType === 'BET' ||
                    cred.transactionType === 'BET_RESULT' ? (
                        <>
                          <button
                              className="btn btn-link text-primary p-0 "
                              onClick={() => {
                                reference.current = cred.remarks;
                                setBetInfoModal(true);
                              }}
                            >
                            {cred.transactionType}
                          </button>
                        </>
                      ):
                      cred.transactionType}
                  </td>


                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="text-dark text-center" colSpan="4">Total</td>
                <td className="text-right"><CurrencyFormat value={totalCredit} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className={`${totalDebit > 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalDebit} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className="text-dark text-center" colSpan="2"></td>
              </tr>
            </tfoot>
          </table>
          <Pagination
            count={totalPage}
            onChange={handleForm}
            className={classes.pagination}
          />
        </>
      ) : null}

      {/* BetInfo modal */}
      <BetInfo
        setBetInfoModal={setBetInfoModal}
        betInfoModal={betInfoModal}
        reference={reference.current}
      />

    </>
  );
};

export default CreditLog;
