import React, {  useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import Password from './Password';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  borderInactive: {
    backgroundColor: '#e1e1e1'
  },
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

// role is for the data received from the data and givenRole is for "agent , master etc. for functions"

const SubTable = ({
  subs,
  totalPage,
  handleRoleSearch,
  editSub,
  setUser,
  setOpsModal,
}) => {
  const classes = useStyles();
  // modal for password
  const [passwordModal, setPasswordModal] = useState(false);
  // for passing value inside edit function also in password modal
  const username = useRef('');
  // for passing user id in op log
  const memberId = useRef('');

  return (
    <>
      <div className="table-responsive-sm mx-2">
        <table className="table table-sm table-hover table-bordered">
          <thead className="text-center thead-dark">
            <tr>
              <th>No.</th>
              <th scope="col">Name</th>
              <th scope="col">Login Id</th>
              <th scope="col">Last Login Time</th>
              <th scope="col">Create Time</th>
              <th scope="col">Status</th>
              <th scope="col">Privilege</th>
              <th scope="col">Function</th>
            </tr>
          </thead>
          <tbody>
            {subs.map((info, index) => (
              <tr
                className={`${
                  info.status === true ? '' : classes.borderInactive
                }`}
                key={index}
              >
                <td className="text-dark text-center">{index + 1}</td>
                <td className="text-dark">{info.name}</td>
                <td className="text-dark">{info.username}</td>
                <td className="text-dark text-center">
                  {info.lastLogin === null ? (
                    '-'
                  ) : (
                    <>{moment(info.lastLogin).format('DD/MM/YYYY HH:mm')} </>
                  )}
                </td>
                <td className="text-dark text-center">
                  {moment(info.createdAt).format('DD/MM/YYYY HH:mm')}
                </td>
                {info.status === true ? (
                  <td className="text-dark text-center">Active</td>
                ) : (
                  <td className="text-danger text-center">InActive</td>
                )}
                <td className="text-dark text-center">
                  {info.privReport? ( <>Report<br/></>) : null}
                  {info.privBalReport? ( <>Bal Report { info.privBalReportDw? ( <>(Deposit Withdraw)</>) : null }<br/></>) : null}
                  {info.privUser? ( <>User ({info.privUserAgent? ' New Agent ': null}{info.privUserMember? ' New Member ': null}{info.privUserEdit? ' Edit ': null}{info.privUserSetting? ' Setting ': null}{info.privUserShare? ' Share ': null}{info.privUserPassword? ' Password ': null})</>) : null}
                </td>
                <td className="text-primary">
                  <button
                    className="btn btn-link text-primary p-0 "
                    onClick={() => {
                      editSub(info.username);
                      // memberId.current = info.id;
                      // username.current = info.username;
                      // setEditModal(true);
                    }}
                  >
                    Edit
                  </button>
                  /
                  <button
                    className="btn btn-sm btn-link text-primary p-0"
                    onClick={() => {
                      setUser(info.username);
                      setOpsModal(true);
                    }}
                  >
                    Operation Log
                  </button>
                  /
                  <button
                    className="btn btn-sm btn-link text-primary p-0"
                    onClick={() => {
                      username.current = info.username;
                      setPasswordModal(true);
                    }}
                  >
                    Password
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        count={totalPage}
        onChange={handleRoleSearch}
        className={classes.pagination}
      />
      {/* password modal */}
      <Password
        passwordModal={passwordModal}
        setPasswordModal={setPasswordModal}
        username={username.current}
        sub='Y'/>      
    </>
  );
};

export default SubTable;
