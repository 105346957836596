import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from '../../../config';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const OperationLogM = () => {
  const classes = useStyles();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const [username, setUsername] = useState(null);
  const [totalPage, setTotalPage] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [OperationLog, setOperationLog] = useState(null);
  const handleForm = (e, value) => {
    if (e != null) e.preventDefault();
    axiosInstance
      .get(`/agent/members/${query.get('id')}/operationLog`, {
        params: {
          startDate: moment(startDate).format('DD-MM-YYYY'),
          endDate: moment(endDate).format('DD-MM-YYYY'),
          pageNo: value - 1,
          pageSize: 30,
        },
      })
      .then((res) => {
        console.log(res.data);
        setOperationLog(res.data.content);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (username == null) {
      axiosInstance
        .get(`/agent/members/${query.get('id')}`, {
          params: {
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
          },
        })
        .then((res) => {
          setUsername(res.data.username);
        })
        .catch((err) => console.log(err));
    }
    handleForm(null, 1);
    // axiosInstance
    //   .get(`/agent/members/${query.get('id')}/operationLog`,{
    //     params: {
    //       startDate: startDate? moment(startDate).format('DD-MM-YYYY'):null,
    //       endDate: endDate? moment(endDate).format('DD-MM-YYYY'): null,
    //     }})
    //   .then((res) => {
    //     // console.log(res.data.content);
    //     setOperationLog(res.data.content);
    //   })
    //   .catch((err) => console.log(err));
  }, [startDate, endDate]);

  return (
    <>
      {OperationLog !== null ? (
        <>
          <p />
          <p />
          <form action="" onSubmit={handleForm} id="form">
            <h6>Operation Log {username}</h6>
            <div className="text-dark d-flex align-items-center">
              <strong>Start Date:</strong>
              <DatePicker
                selected={startDate ? startDate : new Date()}
                onChange={(date) => setStartDate(date)}
                className="mx-2"
                dateFormat="d MMM yyyy"
              />
              <div>
                <strong>End Date:</strong>
                <DatePicker
                  selected={endDate ? endDate : new Date()}
                  onChange={(date) => setEndDate(date)}
                  className="mx-2"
                  dateFormat="d MMM yyyy"
                />
              </div>
            </div>
          </form>
          <p />
          <table className="table table-sm table-striped table-bordered ">
            <thead className="text-center thead-dark">
              <tr>
                <th>No.</th>
                <th>Time</th>
                <th>Username</th>
                <th>IP</th>
                <th>Activity Type</th>
                <th>Error Code</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {OperationLog.map((op, index) => (
                <tr key={op.pubId} className="text-center ">
                  <td className="text-secondary">{index + 1}</td>
                  <td className="text-secondary">
                    {moment(op.createdAt).format('DD MMM h:mm a')}
                  </td>
                  <td className="text-secondary">{op.username}</td>
                  <td className="text-secondary">{op.ip}</td>
                  <td className="text-secondary">{op.activityType}</td>
                  <td className="text-secondary">{op.errorCode}</td>
                  <td className="text-secondary">{op.remarks}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            count={totalPage}
            onChange={handleForm}
            className={classes.pagination}
          />
        </>
      ) : null}
    </>
  );
};

export default OperationLogM;
