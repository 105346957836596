import React, {useState, useEffect} from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../../../config';
import qs from 'querystring';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const BetInfo = ({ betInfoModal, setBetInfoModal, reference }) => {
  const classes = useStyles();
  const history = useHistory();

  const [errMessage, setErrMessage] = useState(null);
  const [bet, setBet] = useState(null);

  const handleClose = () => {
    setBetInfoModal(false);
  };

  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    axiosInstance
      .get('agent/generalReport/betInfo', {
        params: {
          reference: reference? reference: null
        },
      })
      .then((res) => {
        setBet(res.data);
        bet = bet;
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  useEffect(() => {
    if (reference != null && betInfoModal === true) {
      setErrMessage(null);
    }
    if(betInfoModal == true) {
      handleChange(null);
    }
  }, [reference, betInfoModal]);

  return (
    <Modal
      open={betInfoModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
      <br />

<table className="table table-sm table-striped table-bordered">
  <thead className="text-center thead-dark">
  <tr>
    <th scope="col">No</th>
    <th scope="col">Username</th>
    <th scope="col">Date</th>
    <th scope="col">Reference</th>
    <th scope="col">Bet Type</th>
    <th scope="col">Bet Details</th>
    <th scope="col">Amount</th>
    <th scope="col">Result</th>
    <th scope="col">Winning</th>
    <th scope="col">Status</th>
  </tr>

  <>
    {bet !== null ? (
      <>

        <tr className={classes.border} >
          <td className="text-dark">{1}</td>
          <td className="text-dark text-left">{bet.playerName}</td>
          <td className="text-dark text-left">
            {moment(bet.created).format('DD/MM/YYYY HH:mm')}
          </td>
          <td className="text-dark text-left">{bet.reference}</td>
          <td className="text-dark text-left">
            {bet.market === 'PARLAY' ? (
              bet.type
            ) : (
              <>
                {bet.sport} {bet.market}
                <br />
                {bet.game}
              </>
            )}
          </td>
          <td className="text-dark text-left">
            {bet.market === 'PARLAY' ? (
              <>
                Mix Parlay @ {bet.odds.toFixed(2)}
                <table
                  className="table table-sm table-striped"
                  width="300"
                >
                  {bet.parlays.map((parlay) => (
                    <>
                      <tr>
                        <td width="200">
                          <span
                            className={`${classes.compName} ${
                              parlay.status === 'REJECTED'
                                ? classes.reject
                                : null
                            }`}
                          >
                            {parlay.comp}
                          </span>
                          <br />
                          <span
                            className={`${classes.teams} ${
                              parlay.status === 'REJECTED'
                                ? classes.reject
                                : null
                            }`}
                          >
                            <span
                              className={
                                parlay.high === 1
                                  ? classes.strong
                                  : null
                              }
                            >
                              {parlay.home} vs{' '}
                            </span>
                            <span
                              className={
                                parlay.high === 2
                                  ? classes.strong
                                  : null
                              }
                            >
                              {parlay.away}
                            </span>
                          </span>
                          <br />
                          {parlay.market === 'LIVE' ? (
                            <>
                              <span className={classes.running}>
                                (Running {parlay.homeGoal}-
                                {parlay.awayGoal})
                              </span>
                              <br />
                            </>
                          ) : null}
                          <span
                            className={`${classes.handiCap} ${
                              parlay.status === 'REJECTED'
                                ? classes.reject
                                : null
                            }`}
                          >
                            <span className={classes.compName}>
                              {parlay.desc}{' '}
                              {parlay.handicap !== '' ? (
                                <span className={classes.handiCap}>
                                  ({parlay.handicap}){' '}
                                </span>
                              ) : null}
                              <span>
                                @ {parlay.odds.toFixed(2)}
                              </span>
                            </span>
                          </span>
                        </td>
                        <td width="50">
                          {parlay.ftHome}-{parlay.ftAway} (
                          {parlay.fhHome}-{parlay.fhAway})
                        </td>
                        <td width="50">
                          {parlay.result.charAt(0).toUpperCase() +
                            parlay.result
                              .slice(1)
                              .toLowerCase()
                              .replace('_', ' ')}
                        </td>
                      </tr>
                    </>
                  ))}
                </table>
              </>
            ) : (
              <>
                <span
                  className={`${classes.compName} ${
                    bet.status === 'REJECTED'
                      ? classes.reject
                      : null
                  }`}
                >
                  {bet.comp}
                </span>
                <br />
                <span
                  className={`${classes.teams} ${
                    bet.status === 'REJECTED'
                      ? classes.reject
                      : null
                  }`}
                >
                  <span
                    className={
                      bet.high === 1 ? classes.strong : null
                    }
                  >
                    {bet.home} vs{' '}
                  </span>
                  <span
                    className={
                      bet.high === 2 ? classes.strong : null
                    }
                  >
                    {bet.away}
                  </span>
                </span>
                <br />
                {bet.market === 'LIVE' ? (
                  <>
                    <span className={classes.running}>
                      (Running {bet.homeGoal}-{bet.awayGoal})
                    </span>
                    <br />
                  </>
                ) : null}
                <span
                  className={`${classes.handiCap} ${
                    bet.status === 'REJECTED'
                      ? classes.reject
                      : null
                  }`}
                >
                  <span className={classes.compName}>
                    {bet.desc}{' '}
                    {bet.handicap !== '' ? (
                      <span className={classes.handiCap}>
                        ({bet.handicap}){' '}
                      </span>
                    ) : null}
                    <span>@ {bet.odds.toFixed(2)}</span>
                  </span>
                </span>
              </>
            )}
          </td>
          <td className="text-dark text-right">
            <CurrencyFormat
              value={bet.amount}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
            />
          </td>
          <td className={`text-right${bet.result === 'PENDING'?' text-danger':''}`}>
            {bet.result.charAt(0).toUpperCase() +
              bet.result.slice(1).toLowerCase().replace('_', ' ')}
          </td>
          <td className="text-dark text-right">
            <CurrencyFormat
              value={bet.winning}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
            />
          </td>
          <td className={`text-right${bet.status === 'REJECTED'?' text-danger':''}`}>
            {bet.status}
          </td>
        </tr>

        </>
      ) : null}
    </>

  </thead>
</table>


                <div className="d-flex justify-content-center">

                  <button
                    className="btn btn-sm btn-primary px-3 py-1"
                    onClick={() => {
                      setBetInfoModal(false);
                      handleClose();
                    }}
                  >
                    Done
                  </button>
                </div>
                <br />

      </div>
    </Modal>
  );
};

export default BetInfo;
