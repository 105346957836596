import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import CurrencyFormat from 'react-currency-format';
import '../styles/styles.css';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  reject: {
    textDecoration: 'line-through',
  },
  compName: {
    color: '#0E2336',
    fontWeight: 'bold',
  },
  teams: {
    color: '#323030',
  },
  strong: {
    color: '#DC252F',
  },
  handiCap: {
    color: '#1452CC ',
    fontWeight: 'bold',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const BetList = ({ bets, handleChange, username, playerWager, playerWinning, totalPage }) => {
  const [updateComp, setUpdateComp] = useState(new Date()); 
  const classes = useStyles();
  const betResult = (bet) => {
    return bet.result.charAt(0).toUpperCase() + bet.result.slice(1).toLowerCase().replace('_', ' ');
  };

  return (
    <>
      <table className="table table-sm table-striped table-bordered">
        <thead className="text-center thead-dark">
          <tr>
            <th scope="col">No</th>
            <th scope="col">Username</th>
            <th scope="col">Date</th>
            <th scope="col">Reference</th>
            <th scope="col">Bet Type</th>
            <th scope="col">Bet Details</th>
            <th scope="col">Amount</th>
            <th scope="col">Result</th>
            <th scope="col">Winning</th>
          </tr>
        </thead>
        <tbody>
          {bets.map((bet, index) => (
            <tr className={classes.border} key={index}>
              <td className="text-dark text-center">{index + 1}</td>
              <td className="text-left">{bet.account}</td>
              <td className="text-left">
                {moment(bet.created).format('DD/MM/YYYY HH:mm')}
              </td>
              <td className="text-left">{bet.reference}</td>
              <td className="text-left">
                {bet.market === 'PARLAY' ? (
                  bet.type
                ) : (
                  <>
                    {bet.sport} {bet.market}
                    <br />
                    {bet.game}
                  </>
                )}
              </td>
              <td className="text-left">
                {bet.market === 'PARLAY' ? (
                  <>
                    Mix Parlay @ {bet.odds.toFixed(2)} 
                    {bet.showParley?(<button onClick={() => {
                      bet.showParley = false;
                      setUpdateComp(new Date());
                    }} className="btn btn-link text-danger mx-1 px-1 py-1 noborder">Hide</button>):
                    (<button onClick={() => {
                      bet.showParley = true;
                      setUpdateComp(new Date());
                    }} className="btn btn-link text-primary mx-1 px-1 py-1 noborder">Show</button>)}
                    
                    {bet.showParley?(
                    <table
                      className="table table-sm table-striped"
                      width="300"
                    >
                      {bet.parlays.map((parlay) => (
                        <>
                          <tr>
                            <td width="200">
                              <span
                                className={`${classes.compName} ${
                                  parlay.status === 'REJECTED'
                                    ? classes.reject
                                    : null
                                }`}
                              >
                                {parlay.comp}
                              </span>
                              <br />
                              <span
                                className={`${classes.teams} ${
                                  parlay.status === 'REJECTED'
                                    ? classes.reject
                                    : null
                                }`}
                              >
                                <span
                                  className={
                                    parlay.high === 1
                                      ? classes.strong
                                      : null
                                  }
                                >
                                  {parlay.home} vs{' '}
                                </span>
                                <span
                                  className={
                                    parlay.high === 2
                                      ? classes.strong
                                      : null
                                  }
                                >
                                  {parlay.away}
                                </span>
                              </span>
                              <br />
                              {parlay.market === 'LIVE' ? (
                                <>
                                  <span className={classes.running}>
                                    (Running {parlay.homeGoal}-
                                    {parlay.awayGoal})
                                  </span>
                                  <br />
                                </>
                              ) : null}
                              <span
                                className={`${classes.handiCap} ${
                                  parlay.status === 'REJECTED'
                                    ? classes.reject
                                    : null
                                }`}
                              >
                                <span className={classes.compName}>
                                  {parlay.desc}{' '}
                                  {parlay.handicap !== '' ? (
                                    <span className={classes.handiCap}>
                                      ({parlay.handicap}){' '}
                                    </span>
                                  ) : null}
                                  <span>
                                    @ {parlay.odds.toFixed(2)}
                                  </span>
                                </span>
                              </span>
                            </td>
                            <td width="50">
                              {parlay.ftHome}-{parlay.ftAway} (
                              {parlay.fhHome}-{parlay.fhAway})
                            </td>
                            <td width="50">
                              <span className={`${
                                (betResult(parlay) === 'Win' || betResult(parlay) === 'Win half')?
                                'text-success': ((betResult(parlay) === 'Lose' || 
                                betResult(parlay) === 'Lose half')? 'text-danger': '')
                                }`}>
                              {betResult(parlay)}
                              </span>
                            </td>
                          </tr>
                        </>
                      ))}
                    </table>
                    ): null}
                  </>
                ) : (
                  <>
                    <span
                      className={`${classes.compName} ${
                        bet.status === 'REJECTED'
                          ? classes.reject
                          : null
                      }`}
                    >
                      {bet.comp}
                    </span>
                    <br />
                    <span
                      className={`${classes.teams} ${
                        bet.status === 'REJECTED'
                          ? classes.reject
                          : null
                      }`}
                    >
                      <span
                        className={
                          bet.high === 1 ? classes.strong : null
                        }
                      >
                        {bet.home} vs{' '}
                      </span>
                      <span
                        className={
                          bet.high === 2 ? classes.strong : null
                        }
                      >
                        {bet.away}
                      </span>
                    </span>
                    <br />
                    {bet.market === 'LIVE' ? (
                      <>
                        <span className={classes.running}>
                          (Running {bet.homeGoal}-{bet.awayGoal})
                        </span>
                        <br />
                      </>
                    ) : null}
                    <span
                      className={`${classes.handiCap} ${
                        bet.status === 'REJECTED'
                          ? classes.reject
                          : null
                      }`}
                    >
                      <span className={classes.compName}>
                        {bet.desc}{' '}
                        {bet.handicap !== '' ? (
                          <span className={classes.handiCap}>
                            ({bet.handicap}){' '}
                          </span>
                        ) : null}
                        <span>@ {bet.odds.toFixed(2)}</span>
                      </span>
                    </span>
                  </>
                )}
              </td>
              <td className="text-right">
                <CurrencyFormat
                  value={bet.amount}
                  decimalScale={2}
                  displayType={'text'}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                />
              </td>
              <td className="text-center">
                {bet.status === 'REJECTED' || bet.status === 'CANCELLED' ? (
                  <span className="text-danger">{bet.status.charAt(0).toUpperCase() + bet.status.slice(1).toLowerCase().replace('_', ' ')}<br/>{bet.rejectReason}</span>
                ):
                (<span className={`${
                  (betResult(bet) === 'Win' || betResult(bet) === 'Win half')?
                  'text-success': ((betResult(bet) === 'Lose' || betResult(bet) === 'Lose half')? 'text-danger': '')
                  }`}>
                  {betResult(bet)}
                  </span>
                )}
                
              </td>
              <td className={`text-right ${bet.winning < 0? 'text-danger': ''}`}>
                <CurrencyFormat
                  value={bet.winning}
                  decimalScale={2}
                  displayType={'text'}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td className="text-center" colSpan="6">Total</td>
            <td className={`text-right`}><CurrencyFormat value={playerWager} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
            <td>&nbsp;</td>
            <td className={`text-right ${playerWinning < 0? 'text-danger': ''}`}><CurrencyFormat value={playerWinning} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
          </tr>
        </tfoot>
      </table>
      <Pagination
        count={totalPage}
        onChange={handleChange}
        className={classes.pagination}
      />
      </>
  )
}

export default BetList;