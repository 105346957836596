import React, {useEffect, useState} from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import CurrencyFormat from 'react-currency-format';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const Cash = ({
  cashModal,
  setCashModal,
  type,
  username,
  refresh,
}) => {
  const classes = useStyles();
  const [validPwd, setValidPwd] = useState(true);
  const [selected, setSelected] = useState([]);
  const history = useHistory();
  const handleClose = () => {
    setCashModal(false);
  };

  // handle Withdraw
  const handleCash = (e) => {
    e.preventDefault();
    const requestBody = {
      username: username,
      type: type === 'Cash In'?'CASHIN':'CASHOUT',
      amount: e.target.withdraw.value,
      password: e.target.pwd.value,
    };
    axiosInstance
      .post('/agent/agentTxns', qs.stringify(requestBody))
      .then((res) => {
        // console.log('success? '+res.status);
        if (res){
          toast.success('Success!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            });
          setCashModal(false);
          refresh(null);
          console.log(res);
        }
      })
      .catch((err) => {
        console.log('Err: '+err);
        console.log('Err: '+err.response);
        if (err.response && err.response.data.message === 'AG200'){
          setValidPwd(false);
        }
        else if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  useEffect(() => {
    if (username != null && cashModal === true) {
      axiosInstance
      .get('/agent/agents/username/'+username)
      .then((res) => {
        console.log(res);
        setSelected(res.data);
      })
      .catch((err) => console.log(err));
    }
  }, [username]);

  return (
    <Modal
      open={cashModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form action="" onSubmit={handleCash}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className="bg-primary text-light font-weight-bold"
                >
                  {type} for {username}
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              <tr>
                <td className="text-body font-weight-bold">Your Password</td>
                <td>
                  <input type="password" id="pwd" required /> {validPwd === false? (
                    <font color="red">Invalid password</font>
                  ): null}
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Login Id</td>
                <td>{username}</td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Name</td>
                <td>{selected?selected.name:null}</td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Available Balance</td>
                <td><CurrencyFormat value={selected?selected.availableBalance:0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Credit</td>
                <td><CurrencyFormat value={selected?selected.credit:0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Withdraw Amount</td>
                <td>
                  <input type="number" id="withdraw" required max={type === 'Cash In'? selected.credit - selected.availableBalance : selected.availableBalance} /> Max: 
                    <CurrencyFormat value={type === 'Cash In'? selected.credit - selected.availableBalance : selected.availableBalance} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/> 
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Remark</td>
                <td>
                  <textarea
                    name=""
                    id="withdraw-remark"
                    cols="30"
                    rows="3"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-sm btn-primary mx-3 px-3 py-1"
                    />

                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        setCashModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </Modal>
  );
};

export default Cash;
