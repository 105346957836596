import React, {useEffect, useState} from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../../../config';
import qs from 'querystring';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));
const Setting = ({ settingModal, setSettingModal, id, username }) => {
  const classes = useStyles();
  const [minSingleLimit, setMinSingleLimit] = useState(null);
  const [minSingleMessage, setMinSingleMessage] = useState(null);
  const [maxSingleLimit, setMaxSingleLimit] = useState(null);
  const [maxSingleMessage, setMaxSingleMessage] = useState(null);
  const [minParlayLimit, setMinParlayLimit] = useState(null);
  const [minParlayMessage, setMinParlayMessage] = useState(null);
  const [maxParlayLimit, setMaxParlayLimit] = useState(null);
  const [maxParlayMessage, setMaxParlayMessage] = useState(null);
  const [minTotalLimit, setMinTotalLimit] = useState(null);
  const [maxTotalLimit, setMaxTotalLimit] = useState(null);
  const [totalMessage, setTotalMessage] = useState(null);

  const handleClose = () => {
    setSettingModal(false);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    const form = document.getElementById('form');
    const minSingle = form['minSingle'].value;
    const maxSingle = form['maxSingle'].value;
    const minParlay = form['minParlay'].value;
    const maxParlay = form['maxParlay'].value;
    const maxTotal = form['maxTotal'].value;
    if (parseFloat(minSingle) < minSingleLimit || parseFloat(minSingle) > parseFloat(maxSingle) || parseFloat(minSingle) > maxSingleLimit) {
      setMinSingleMessage('Invalid min single');
      return false;
    }
    else setMinSingleMessage(null);
    if (parseFloat(maxSingle) > maxSingleLimit || parseFloat(minSingle) > parseFloat(maxSingle) || parseFloat(maxSingle) < minSingleLimit) {
      setMaxSingleMessage('Invalid max single');
      return false;
    }
    else setMaxSingleMessage(null);
    if (parseFloat(minParlay) < minParlayLimit || parseFloat(minParlay) > parseFloat(maxParlay) || parseFloat(minParlay) > maxParlayLimit) {
      setMinParlayMessage('Invalid min parlay');
      return false;
    }
    else setMinParlayMessage(null);
    if (parseFloat(maxParlay) > maxParlayLimit || parseFloat(minParlay) > parseFloat(maxParlay) || parseFloat(maxParlay) < minParlayLimit) {
      setMaxParlayMessage('Invalid max parlay');
      return false;
    }
    else setMaxParlayMessage(null);
    if (parseFloat(maxTotal) > maxTotalLimit || parseFloat(maxTotal) < minTotalLimit) {
      setTotalMessage('Invalid game limit');
      return false;
    }
    else setTotalMessage(null);
    const requestBody = {
      minSingle: minSingle,
      maxSingle: maxSingle,
      minParlay: minParlay,
      maxParlay: maxParlay,
      maxTotal: maxTotal,
    };
    axiosInstance.post(`/agent/members/${id}/setting`, qs.stringify(requestBody))
    .then((res) => {
      console.log(res);
      toast.success('Success!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        });
      setSettingModal(false);
    })
    .catch((err) => {
      console.log(err);
      toast.error('Failed:['+err.response.status+']'+err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    });
  }

  useEffect(() => {
    // console.log('use effect setting');
    setMinSingleMessage(null);
    setMaxSingleMessage(null);
    setMinParlayMessage(null);
    setMaxParlayMessage(null);
    setTotalMessage(null);
    if (settingModal === true) {
      axiosInstance
      .get(`/agent/members/${id}/setting`)
      .then((res) => {
        setMinSingleLimit(res.data.minSingleLimit);
        setMaxSingleLimit(res.data.maxSingleLimit);
        setMinParlayLimit(res.data.minParlayLimit);
        setMaxParlayLimit(res.data.maxParlayLimit);
        setMinTotalLimit(res.data.minTotalLimit);
        setMaxTotalLimit(res.data.maxTotalLimit);
        const form = document.getElementById('form');
        console.log('form = '+form);
        form['minSingle'].value = res.data.minSingle;
        form['minParlay'].value = res.data.minParlay;
        form['maxSingle'].value = res.data.maxSingle;
        form['maxParlay'].value = res.data.maxParlay;
        form['maxTotal'].value = res.data.maxTotal;
      })
      .catch((err) => console.log(err));
    }
  }, [settingModal, id]);

  return (
    <Modal
      open={settingModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form action="" onSubmit={handleEdit} id="form">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="4"
                  className="bg-primary text-light font-weight-bold"
                >
                  Setting for {username}
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              <tr>
                <td
                  colSpan="3"
                  className="font-weight-bold"
                >
                  Bet Limit
                </td>
              </tr>
              <tr>
                <td rowSpan="2" className="text-center align-middle">
                  Single
                </td>
                <td>Min</td>
                <td><input type="text" placeholder="0.00" id="minSingle" /> min: {minSingleLimit} <font color="red">{minSingleMessage}</font></td>
              </tr>
              <tr>
                <td>Max</td>
                <td><input type="text" placeholder="0.00" id="maxSingle" /> max: {maxSingleLimit} <font color="red">{maxSingleMessage}</font></td>
              </tr>
              <tr>
                <td rowSpan="2" className="text-center align-middle">
                  Parlay
                </td>
                <td>Min</td>
                <td><input type="text" placeholder="0.00" id="minParlay" /> min: {minParlayLimit} <font color="red">{minParlayMessage}</font></td>
              </tr>
              <tr>
                <td>Max</td>
                <td><input type="text" placeholder="0.00" id="maxParlay" /> max: {maxParlayLimit} <font color="red">{maxParlayMessage}</font></td>
              </tr>
              <tr>
                <td
                  colSpan="3"
                  className="font-weight-bold"
                >
                  Game Limit
                </td>
              </tr>
              <tr>
                <td colSpan="2">Game Limit</td>
                <td><input type="text" placeholder="0.00" id="maxTotal" /> min: {minTotalLimit}, max: {maxTotalLimit} <font color="red">{totalMessage}</font></td>
              </tr>
              <tr>
                <td colSpan="3">
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-sm btn-primary mx-3 px-3 py-1"
                    />
                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        setSettingModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </Modal>
  );
};

export default Setting;
