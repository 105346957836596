import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../../../config';
import qs from 'querystring';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const Deposit = ({
  depositModal,
  setDepositModal,
  username,
  name,
  balance,
  deposit,
  setMember,
  setTotalPage,
}) => {
  const classes = useStyles();
  const handleClose = () => {
    setDepositModal(false);
  };

  const [validPwd, setValidPwd] = useState(true);

  // handle Deposit
  const handleDeposit = (e) => {
    setValidPwd(true);
    e.preventDefault();
    const requestBody = {
      username: username,
      type: 'DEPOSIT',
      amount: e.target.deposit.value,
      password: document.getElementById('deposit-pwd').value,
      remarks: document.getElementById('deposit-remark').value,
    };
    axiosInstance
      .post('/agent/txns', qs.stringify(requestBody))
      .then((res) => {
        console.log(res);
        toast.success('Success!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setDepositModal(false);
        // update members
        const memberForm = document.getElementById('member-form');
        const searchName = memberForm['member-name'].value;
        const searchUser = memberForm['member-login'].value;
        const searchStatus = memberForm['member-status'].value;
        const sort = memberForm['member-sort'].value;
        const asc = memberForm['member-asc'].value;
        axiosInstance
          .get('/agent/members', {
            params: {
              searchName: searchName !== '' ? searchName : null,
              searchUser: searchUser !== '' ? searchUser : null,
              searchStatus: searchStatus !== '' ? searchStatus : null,
              sort: sort !== '' ? sort : null,
              asc: asc !== '' ? asc : null,
            },
          })
          .then((res) => {
            console.log(res);
            setMember(res.data.content);
            setTotalPage(res.data.totalPages);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.message === 'AG200') {
          setValidPwd(false);
        }
      });
  };

  useEffect(() => {
    if (depositModal === true) {
      setValidPwd(true);
    }
  }, [depositModal]);

  return (
    <>
      <Modal
        open={depositModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalStyle}
      >
        <div className={classes.Modal}>
          <form action="" onSubmit={handleDeposit}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    colSpan="2"
                    className="bg-primary text-light font-weight-bold"
                  >
                    Deposit
                  </th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                <tr>
                  <td className="text-body font-weight-bold">Your Password</td>
                  <td>
                    <input type="password" id="deposit-pwd" required />
                    {validPwd === false ? (
                      <font color="red">Invalid password</font>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td className="text-body font-weight-bold">Login Id</td>
                  <td>{username}</td>
                </tr>
                <tr>
                  <td className="text-body font-weight-bold">Name</td>
                  <td>{name}</td>
                </tr>
                <tr>
                  <td className="text-body font-weight-bold">Balance</td>
                  <td>{balance}</td>
                </tr>
                <tr>
                  <td className="text-body font-weight-bold">Deposit Amount</td>
                  <td>
                    <input
                      type="number"
                      id="deposit"
                      min={0}
                      max={deposit ? deposit.limit : null}
                      required
                    />
                    {deposit !== null ? (
                      <label htmlFor="deposit" className="ml-2">
                        {' '}
                        Max ({deposit.limit})
                      </label>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td className="text-body font-weight-bold ">Remark</td>
                  <td>
                    <textarea
                      name=""
                      id="deposit-remark"
                      cols="30"
                      rows="3"
                    ></textarea>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div className="d-flex justify-content-end">
                      <input
                        type="submit"
                        value="Submit"
                        className="btn btn-sm btn-primary mx-3 px-3 py-1"
                      />

                      <button
                        className="btn btn-sm btn-danger px-3 py-1"
                        onClick={() => {
                          setDepositModal(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Deposit;
