import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pointer: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const ReportNavbar = ({ isReportNavbar, role }) => {
  const classes = useStyles();
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light bg-light py-0 border-bottom ${
        isReportNavbar !== true ? 'd-none' : 'd-block'
      }`}
      id="report-navbar"
    >
      <div>
        <div className="navbar-nav position-relative">
          <Link
            className={`nav-link text-dark${
              window.location.pathname === '/wl' ? ' active' : ''
            }`}
            to="/wl"
          >
            Win Lose
          </Link>
          <Link
            className={`nav-link text-dark${
              window.location.pathname === '/wlDetail' ? ' active' : ''
            }`}
            to="/wlDetail"
          >
            W/L Detail
          </Link>
          <Link
            className={`nav-link text-dark${
              window.location.pathname === '/wlType' ? ' active' : ''
            }`}
            to="/wlType"
          >
            Type W/L
          </Link>
          <Link
            className={`nav-link text-dark${
              window.location.pathname === '/win' ? ' active' : ''
            }`}
            to="/win"
          >
            Win
          </Link>
          {/* <Link
            className={`nav-link text-dark${
              window.location.pathname === '/outstanding' ? ' active' : ''
            }`}
            to="/outstanding"
          >
            Outstanding
          </Link> */}
          <Link
            className={`nav-link text-dark${
              window.location.pathname === '/out' ? ' active' : ''
            }`}
            to="/out"
          >
            Outstanding
          </Link>
          <Link
            className={`nav-link text-dark${
              window.location.pathname === '/outDetail' ? ' active' : ''
            }`}
            to="/outDetail"
          >
            Outstanding Detail
          </Link>
          <Link
            className={`nav-link text-dark${
              window.location.pathname === '/outType' ? ' active' : ''
            }`}
            to="/outType"
          >
            Type Outstanding
          </Link>
          <Link
            className={`nav-link text-dark${
              window.location.pathname === '/cancel' ? ' active' : ''
            }`}
            to="/cancel"
          >
            Cancel
          </Link>
          {/* {role === 'COMPANY' || role === 'VENDOR' ? (
            <Link
              className={`nav-link text-dark${
                window.location.pathname === '/gamecategory' ? ' active' : ''
              }`}
              to="/gamecategory"
            >
              Game Category
            </Link>
          ) : null} */}

          <Link
            className={`nav-link text-dark${
              window.location.pathname === '/sharerate' ? ' active' : ''
            }`}
            to="/sharerate"
          >
            Share Rate
          </Link>
          <Link
            className={`nav-link text-dark${
              window.location.pathname === '/bigwinner' ? ' active' : ''
            }`}
            to="/bigwinner"
          >
            Big Winner
          </Link>
          {/* <Link
            className={`nav-link text-dark${
              window.location.pathname === '/winlose' ? ' active' : ''
            }`}
            to="/winlose"
          >
            Win Lose
          </Link> */}
        </div>
      </div>
    </nav>
  );
};

export default ReportNavbar;
