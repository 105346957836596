import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));
const Stake = ({ stakeModal, setStakeModal, id, username }) => {
  const classes = useStyles();
  const [stakes, setStakes] = useState([]);
  const [error, setError] = useState(null);
  const [footballId, setFootballId] = useState(null);
  const [basketballId, setBasketballId] = useState(null);
  const handleClose = () => {
    setStakeModal(false);
  };
  const history = useHistory();
  const handleEdit = (sportId, sportName) => {
    if (sportId != null && sportId !== 'parlay') {
      const lstake = document.getElementById(sportId + 'lstake').value;
      const lcommission = document.getElementById(sportId + 'lcomm').value;
      const tstake = document.getElementById(sportId + 'tstake').value;
      const tcommission = document.getElementById(sportId + 'tcomm').value;
      const estake = document.getElementById(sportId + 'estake').value;
      const ecommission = document.getElementById(sportId + 'ecomm').value;

      const ulstake = document.getElementById(sportId + 'ulstake').value;
      const ulcommission = document.getElementById(sportId + 'ulcomm').value;
      const utstake = document.getElementById(sportId + 'utstake').value;
      const utcommission = document.getElementById(sportId + 'utcomm').value;
      const uestake = document.getElementById(sportId + 'uestake').value;
      const uecommission = document.getElementById(sportId + 'uecomm').value;

      const dlstake = document.getElementById(sportId + 'dlstake').value;
      const dlcommission = document.getElementById(sportId + 'dlcomm').value;
      const dtstake = document.getElementById(sportId + 'dtstake').value;
      const dtcommission = document.getElementById(sportId + 'dtcomm').value;
      const destake = document.getElementById(sportId + 'destake').value;
      const decommission = document.getElementById(sportId + 'decomm').value;

      let errorMessage = '';
      if (
        parseFloat(lstake) > parseFloat(ulstake) ||
        parseFloat(lstake) < parseFloat(dlstake)
      ) {
        errorMessage += ' live stake,';
      }
      if (
        parseFloat(lcommission) > parseFloat(ulcommission) ||
        parseFloat(lcommission) < parseFloat(dlcommission)
      ) {
        errorMessage += ' live commission,';
      }
      if (
        parseFloat(tstake) > parseFloat(utstake) ||
        parseFloat(tstake) < parseFloat(dtstake)
      ) {
        errorMessage += ' today stake,';
      }
      if (
        parseFloat(tcommission) > parseFloat(utcommission) ||
        parseFloat(tcommission) < parseFloat(dtcommission)
      ) {
        errorMessage += ' today commission,';
      }
      if (
        parseFloat(estake) > parseFloat(uestake) ||
        parseFloat(estake) < parseFloat(destake)
      ) {
        errorMessage += ' early stake,';
      }
      if (
        parseFloat(ecommission) > parseFloat(uecommission) ||
        parseFloat(ecommission) < parseFloat(decommission)
      ) {
        errorMessage += ' early commission,';
      }
      if (errorMessage.length > 0) {
        toast.error(
          'Invalid' + errorMessage.slice(0, -1) + ' ' + sportName + ' value!',
          {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
        return false;
      }

      const requestBody = {
        sportId: sportId,
        liveStake: lstake,
        liveCommission: lcommission,
        todayStake: tstake,
        todayCommission: tcommission,
        earlyStake: estake,
        earlyCommission: ecommission,
      };
      axiosInstance
        .post(
          `/agent/agents/${id}/agentStakeSetting`,
          qs.stringify(requestBody)
        )
        .then((res) => {
          toast.success('Success!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setError(null);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 400) {
            setError('Error, invalid value!');
          } else if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else if (sportId === 'parlay') {
      const pstake = document.getElementById('parlayStake').value;
      const pcomm = document.getElementById('parlayComm').value;
      const ustake = document.getElementById('uparlayStake').value;
      const ucomm = document.getElementById('uparlayComm').value;
      const dstake = document.getElementById('dparlayStake').value;
      const dcomm = document.getElementById('dparlayComm').value;
      let errorMessage = '';
      if (
        parseFloat(pstake) > parseFloat(ustake) ||
        parseFloat(pstake) < parseFloat(dstake)
      ) {
        errorMessage += ' parlay stake,';
      }
      if (
        parseFloat(pcomm) > parseFloat(ucomm) ||
        parseFloat(pcomm) < parseFloat(dcomm)
      ) {
        errorMessage += ' parlay commission,';
      }
      if (errorMessage.length > 0) {
        toast.error('Invalid' + errorMessage.slice(0, -1) + ' value!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return false;
      }
      const requestBody = {
        parlayStake: pstake,
        parlayCommission: pcomm,
      };
      axiosInstance
        .post(
          `/agent/agents/${id}/agentStakeSetting`,
          qs.stringify(requestBody)
        )
        .then((res) => {
          setError(null);
          toast.success('Success!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 400) {
            toast.error('Error, please reload or try again', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            setError('Error, invalid value!');
          } else if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    }
  };

  useEffect(() => {
    if (stakeModal === true) {
      axiosInstance
        .get(`/agent/agents/${id}/agentStakeSetting`)
        .then((res) => {
          setStakes(res.data);
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].sportId != null) {
              let sportId = res.data[i].sportId;
              document.getElementById(sportId + 'lstake').value =
                res.data[i].liveStake;
              document.getElementById(sportId + 'lcomm').value =
                res.data[i].liveComm;
              document.getElementById(sportId + 'tstake').value =
                res.data[i].todayStake;
              document.getElementById(sportId + 'tcomm').value =
                res.data[i].todayComm;
              document.getElementById(sportId + 'estake').value =
                res.data[i].earlyStake;
              document.getElementById(sportId + 'ecomm').value =
                res.data[i].earlyComm;
            } else if (res.data[i].parlayStake != null) {
              document.getElementById('parlayStake').value =
                res.data[i].parlayStake;
              document.getElementById('parlayComm').value =
                res.data[i].parlayComm;
            }
          }
          setError(null);
        })
        .catch((err) => console.log(err));
      // sport id
      axiosInstance.get('public/selectSport').then((res) => {
        setFootballId(res.data[0].key);
        setBasketballId(res.data[1].key);
      });
    }
  }, [stakeModal, id]);

  // handle all

  const handleAll = (e) => {
    if (e.target.checked) {
      // check all the lower boxes
      document.getElementById(`${basketballId}live`).checked = true;
      document.getElementById(`${basketballId}early`).checked = true;
      document.getElementById(`${basketballId}today`).checked = true;
      // copyining value from football
      document.getElementById(
        `${basketballId}lstake`
      ).value = document.getElementById(`${footballId}lstake`).value;
      document.getElementById(
        `${basketballId}lcomm`
      ).value = document.getElementById(`${footballId}lcomm`).value;
      document.getElementById(
        `${basketballId}estake`
      ).value = document.getElementById(`${footballId}estake`).value;
      document.getElementById(
        `${basketballId}ecomm`
      ).value = document.getElementById(`${footballId}ecomm`).value;
      document.getElementById(
        `${basketballId}tstake`
      ).value = document.getElementById(`${footballId}tstake`).value;
      document.getElementById(
        `${basketballId}tcomm`
      ).value = document.getElementById(`${footballId}tcomm`).value;
    } else {
      // uncheck lower boxes
      document.getElementById(`${basketballId}live`).checked = false;
      document.getElementById(`${basketballId}early`).checked = false;
      document.getElementById(`${basketballId}today`).checked = false;
      //  reset value to
      document.getElementById(`${basketballId}lstake`).value = 0;
      document.getElementById(`${basketballId}lcomm`).value = 0;
      document.getElementById(`${basketballId}estake`).value = 0;
      document.getElementById(`${basketballId}ecomm`).value = 0;
      document.getElementById(`${basketballId}tstake`).value = 0;
      document.getElementById(`${basketballId}tcomm`).value = 0;
    }
  };

  // handle live
  const handleLive = (e) => {
    if (e.target.checked) {
      document.getElementById(
        `${basketballId}lstake`
      ).value = document.getElementById(`${footballId}lstake`).value;
      document.getElementById(
        `${basketballId}lcomm`
      ).value = document.getElementById(`${footballId}lcomm`).value;
    } else {
      document.getElementById(`${basketballId}lstake`).value = 0;
      document.getElementById(`${basketballId}lcomm`).value = 0;
    }
  };

  // handle Today
  const handleToday = (e) => {
    if (e.target.checked) {
      document.getElementById(
        `${basketballId}tstake`
      ).value = document.getElementById(`${footballId}tstake`).value;
      document.getElementById(
        `${basketballId}tcomm`
      ).value = document.getElementById(`${footballId}tcomm`).value;
    } else {
      document.getElementById(`${basketballId}tstake`).value = 0;
      document.getElementById(`${basketballId}tcomm`).value = 0;
    }
  };

  // handle early
  const handleEarly = (e) => {
    if (e.target.checked) {
      document.getElementById(
        `${basketballId}estake`
      ).value = document.getElementById(`${footballId}estake`).value;
      document.getElementById(
        `${basketballId}ecomm`
      ).value = document.getElementById(`${footballId}ecomm`).value;
    } else {
      document.getElementById(`${basketballId}estake`).value = 0;
      document.getElementById(`${basketballId}ecomm`).value = 0;
    }
  };

  return (
    <Modal
      open={stakeModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form id="form">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="4"
                  className="bg-primary text-light font-weight-bold"
                >
                  Stake % for {username}
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              {stakes.map((stake) => (
                <>
                  {stake.sportId != null ? (
                    <>
                      <tr>
                        {stake.sportId !== footballId ? (
                          <td
                            colSpan="4"
                            className="font-weight-bold d-flex align-items-center"
                          >
                            {stake.sportName}
                            <label
                              htmlFor={`${stake.sportId}box`}
                              className="my-0 d-flex align-items-center "
                            >
                              <input
                                type="checkbox"
                                id={`${stake.sportId}box`}
                                className="mx-1"
                                onChange={handleAll}
                              />
                              Copy
                            </label>
                          </td>
                        ) : (
                          <td colSpan="4" className="font-weight-bold">
                            {stake.sportName}
                          </td>
                        )}
                      </tr>
                      <tr>
                        {stake.sportId !== footballId ? (
                          <td rowSpan="2" className="text-center align-middle">
                            Live <br />
                            <label
                              htmlFor={`${stake.sportId}live`}
                              className="my-0 ml-2  d-flex align-items-center justify-content-center "
                            >
                              <input
                                type="checkbox"
                                id={`${stake.sportId}live`}
                                className="mx-1"
                                onChange={handleLive}
                              />
                              Copy
                            </label>
                          </td>
                        ) : (
                          <td rowSpan="2" className="text-center align-middle">
                            Live
                          </td>
                        )}
                        <td>Stake (%)</td>
                        <td>
                          <input
                            type="number"
                            placeholder="0.00"
                            id={`${stake.sportId}lstake`}
                            min={stake.liveDownlineStake}
                            max={stake.liveUplineStake}
                          />{' '}
                          min: {stake.liveDownlineStake}, max:{' '}
                          {stake.liveUplineStake}{' '}
                          <input
                            type="hidden"
                            id={`${stake.sportId}ulstake`}
                            value={stake.liveUplineStake}
                          />{' '}
                          <input
                            type="hidden"
                            id={`${stake.sportId}dlstake`}
                            value={stake.liveDownlineStake}
                          />
                        </td>
                        <td rowSpan="6" className="text-center align-middle">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary px-3 py-1"
                            onClick={() => {
                              handleEdit(stake.sportId, stake.sportName);
                            }}
                          >
                            Save
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Commission</td>
                        <td>
                          <input
                            type="number"
                            step="0.05"
                            placeholder="0.00"
                            id={`${stake.sportId}lcomm`}
                            min={stake.liveDownlineComm}
                            max={stake.liveUplineComm}
                          />{' '}
                          min: {stake.liveDownlineComm}, max:{' '}
                          {stake.liveUplineComm}{' '}
                          <input
                            type="hidden"
                            id={`${stake.sportId}ulcomm`}
                            value={stake.liveUplineComm}
                          />{' '}
                          <input
                            type="hidden"
                            id={`${stake.sportId}dlcomm`}
                            value={stake.liveDownlineComm}
                          />
                        </td>
                      </tr>
                      <tr>
                        {stake.sportId !== footballId ? (
                          <td rowSpan="2" className="text-center align-middle">
                            Today <br />
                            <label
                              htmlFor={`${stake.sportId}today`}
                              className="my-0 ml-2  d-flex align-items-center justify-content-center "
                            >
                              <input
                                type="checkbox"
                                id={`${stake.sportId}today`}
                                className="mx-1"
                                onChange={handleToday}
                              />
                              Copy
                            </label>
                          </td>
                        ) : (
                          <td rowSpan="2" className="text-center align-middle">
                            Today
                          </td>
                        )}
                        <td>Stake (%)</td>
                        <td>
                          <input
                            type="number"
                            placeholder="0.00"
                            id={`${stake.sportId}tstake`}
                            min={stake.todayDownlineStake}
                            max={stake.todayUplineStake}
                          />{' '}
                          min: {stake.todayDownlineStake}, max:{' '}
                          {stake.todayUplineStake}{' '}
                          <input
                            type="hidden"
                            id={`${stake.sportId}utstake`}
                            value={stake.todayUplineStake}
                          />{' '}
                          <input
                            type="hidden"
                            id={`${stake.sportId}dtstake`}
                            value={stake.todayDownlineStake}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Commission</td>
                        <td>
                          <input
                            type="number"
                            step="0.05"
                            placeholder="0.00"
                            id={`${stake.sportId}tcomm`}
                            min={stake.todayDownlineComm}
                            max={stake.todayUplineComm}
                          />{' '}
                          min: {stake.todayDownlineComm}, max:{' '}
                          {stake.todayUplineComm}{' '}
                          <input
                            type="hidden"
                            id={`${stake.sportId}utcomm`}
                            value={stake.todayUplineComm}
                          />{' '}
                          <input
                            type="hidden"
                            id={`${stake.sportId}dtcomm`}
                            value={stake.todayDownlineComm}
                          />
                        </td>
                      </tr>
                      <tr>
                        {stake.sportId !== footballId ? (
                          <td rowSpan="2" className="text-center align-middle">
                            Early <br />
                            <label
                              htmlFor={`${stake.sportId}early`}
                              className="my-0 ml-2  d-flex align-items-center justify-content-center "
                            >
                              <input
                                type="checkbox"
                                id={`${stake.sportId}early`}
                                className="mx-1"
                                onChange={handleEarly}
                              />
                              Copy
                            </label>
                          </td>
                        ) : (
                          <td rowSpan="2" className="text-center align-middle">
                            Early
                          </td>
                        )}
                        <td>Stake (%)</td>
                        <td>
                          <input
                            type="number"
                            placeholder="0.00"
                            id={`${stake.sportId}estake`}
                            min={stake.earlyDownlineStake}
                            max={stake.earlyUplineStake}
                          />{' '}
                          min: {stake.earlyDownlineStake}, max:{' '}
                          {stake.earlyUplineStake}{' '}
                          <input
                            type="hidden"
                            id={`${stake.sportId}uestake`}
                            value={stake.earlyUplineStake}
                          />{' '}
                          <input
                            type="hidden"
                            id={`${stake.sportId}destake`}
                            value={stake.earlyDownlineStake}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Commission</td>
                        <td>
                          <input
                            type="number"
                            step="0.05"
                            placeholder="0.00"
                            id={`${stake.sportId}ecomm`}
                            min={stake.earlyDownlineComm}
                            max={stake.earlyUplineComm}
                          />{' '}
                          min: {stake.earlyDownlineComm}, max:{' '}
                          {stake.earlyUplineComm}{' '}
                          <input
                            type="hidden"
                            id={`${stake.sportId}uecomm`}
                            value={stake.earlyUplineComm}
                          />{' '}
                          <input
                            type="hidden"
                            id={`${stake.sportId}decomm`}
                            value={stake.earlyDownlineComm}
                          />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan="4" className="font-weight-bold">
                          Parlay
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan="2" className="text-center align-middle">
                          All
                        </td>
                        <td>Stake (%)</td>
                        <td>
                          <input
                            type="number"
                            placeholder="0.00"
                            id="parlayStake"
                            min={stake.parlayDownlineStake}
                            max={stake.parlayUplineStake}
                          />{' '}
                          min: {stake.parlayDownlineStake}, max:{' '}
                          {stake.parlayUplineStake}{' '}
                          <input
                            type="hidden"
                            id={`uparlayStake`}
                            value={stake.parlayUplineStake}
                          />{' '}
                          <input
                            type="hidden"
                            id={`dparlayStake`}
                            value={stake.parlayDownlineStake}
                          />
                        </td>
                        <td rowSpan="2" className="text-center align-middle">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary px-3 py-1"
                            onClick={() => {
                              handleEdit('parlay');
                            }}
                          >
                            Save
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Commission</td>
                        <td>
                          <input
                            type="number"
                            step="0.05"
                            placeholder="0.00"
                            id="parlayComm"
                            min={stake.parlayDownlineComm}
                            max={stake.parlayUplineComm}
                          />{' '}
                          min: {stake.parlayDownlineComm}, max:{' '}
                          {stake.parlayUplineComm}{' '}
                          <input
                            type="hidden"
                            id={`uparlayComm`}
                            value={stake.parlayUplineComm}
                          />{' '}
                          <input
                            type="hidden"
                            id={`dparlayComm`}
                            value={stake.parlayDownlineComm}
                          />
                        </td>
                      </tr>
                    </>
                  )}
                </>
              ))}
              <tr>
                <td colSpan="3">
                  <font color="red">{error}</font>
                </td>
                <td>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        setStakeModal(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </Modal>
  );
};

export default Stake;
