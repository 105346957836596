import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './pages/login/Login';
import Member from './pages/member/Member';
import Message from './pages/message/Message';
import Senior from './pages/senior/Senior';
import WlDetail from './pages/report/WlDetail';
import ProfitLoss from './pages/report/ProfitLoss';
import Outstanding from './pages/report/Outstanding';
import GameCategory from './pages/report/GameCategory';
import WinLose from './pages/balance/WinLose';
import WinLoseDetail from './pages/balance/WinLoseDetail';
import Agent from './pages/agent/Agent';
import ProtectedRoute from './components/ProtectedRoute';
import Master from './pages/master/Master';
import CreditLog from './components/CreditLog';
import Vendor from './pages/vendor/Vendor';
import OperationLog from './components/OperationLog';
import OperationLogM from './pages/member/functions/OperationLogM';
import CreditLogM from './pages/member/functions/CreditLogM';
import Dashboard from './pages/dashboard/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BigWinner from './pages/report/BigWinner';
import Win from './pages/report/Win';
import Cancel from './pages/report/Cancel';
import ShareRate from './pages/report/ShareRate';
import OwnOperationLog from './pages/OwnOperationLog';
import Sub from './pages/agent/Sub';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <ProtectedRoute exact path="/sub" component={Sub} />
          <ProtectedRoute exact path="/member" component={Member} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute exact path="/message" component={Message} />
          <ProtectedRoute exact path="/senior" component={Senior} />
          <ProtectedRoute exact path="/ownOpLog" component={OwnOperationLog} />
          <ProtectedRoute exact path="/wl" component={(props) => <WlDetail {...props} summaryView="Y"/>}/>
          <ProtectedRoute exact path="/wlDetail" component={(props) => <WlDetail {...props} summaryView="N"/>}/>
          <ProtectedRoute exact path="/wlType" component={(props) => <WlDetail {...props} type="Y"/>}/>
          <ProtectedRoute exact path="/out"  component={(props) => <WlDetail {...props} out="O" summaryView="Y"/>}/>
          <ProtectedRoute exact path="/outDetail" component={(props) => <WlDetail {...props} out="O"/>}/>
          <ProtectedRoute exact path="/outType" component={(props) => <WlDetail {...props} type="Y" out="O"/>}/>
          {/* <ProtectedRoute exact path="/wl">
            <WlDetail summaryView="Y" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/wlDetail">
            <WlDetail summaryView="N" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/wlType">
            <WlDetail type="Y" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/out">
            <WlDetail out="O" summaryView="Y" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/outDetail">
            <WlDetail out="O" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/outType">
            <WlDetail type="Y" out="O" />
          </ProtectedRoute> */}
          <ProtectedRoute exact path="/bigwinner" component={BigWinner} />
          <ProtectedRoute exact path="/win" component={Win} />
          <ProtectedRoute exact path="/cancel" component={Cancel} />
          <ProtectedRoute exact path="/sharerate" component={ShareRate} />
          <ProtectedRoute exact path="/report" component={ProfitLoss} />
          <ProtectedRoute exact path="/outstanding" component={Outstanding} />
          <ProtectedRoute exact path="/gamecategory" component={GameCategory} />
          <ProtectedRoute exact path="/agent" component={Agent} />
          <ProtectedRoute exact path="/winlose" component={WinLose} />
          <ProtectedRoute
            exact
            path="/winlosedetail"
            component={WinLoseDetail}
          />
          <ProtectedRoute exact path="/master" component={Master} />
          <ProtectedRoute exact path="/credit" component={CreditLog} />
          <ProtectedRoute exact path="/creditm" component={CreditLogM} />
          <ProtectedRoute exact path="/operation" component={OperationLog} />
          <ProtectedRoute exact path="/operationm" component={OperationLogM} />
          <ProtectedRoute exact path="/vendor" component={Vendor} />
        </Switch>
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
