import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axiosInstance from '../config';
import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const OwnOperationLog = () => {
  const [operationLog, setOperationLog] = useState();
  const [totalPage, setTotalPage] = useState(null);
  const classes = useStyles();

  const history = useHistory();

  // hnadle pagination
  const handleChange = (event, value) => {
    console.log(value);
    axiosInstance
      .get(`agent/agents/ownOperationLog`, {
        params: {
          pageNo: value - 1,
          startDate: '19-11-2020',
          endDate: '19-11-2021',
          pageSize: '50',
        },
      })
      .then((res) => {
        console.log(res.data.content);
        setOperationLog(res.data);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // own op log
    axiosInstance
      .get('agent/agents/ownOperationLog', {
        params: {
          startDate: '19-11-2020',
          endDate: '19-11-2021',
          pageSize: '50',
        },
      })
      .then((res) => {
        console.log(res);
        setTotalPage(res.data.totalPages);
        setOperationLog(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <button
        className="btn btn-sm btn-primary px-3 py-1 my-1 mx-1"
        onClick={() => {
          history.goBack();
        }}
      >
        Go Back
      </button>
      <table className="table table-sm table-striped table-bordered">
        <thead className="text-center thead-dark">
          <tr>
            <th>No.</th>
            <th>Time</th>
            <th>Username</th>
            <th>IP</th>
            <th>Activity Type</th>
            <th>Error Code</th>
            <th>Remarks</th>
          </tr>
        </thead>
        {operationLog !== undefined ? (
          <tbody>
            {operationLog.content.map((op, index) => (
              <tr key={op.pubId} className="text-center ">
                <td className="text-secondary">{index + 1}</td>
                <td className="text-secondary">
                  {moment(op.createdAt).format('DD MMM h:mm a')}
                </td>
                <td className="text-secondary">{op.username}</td>
                <td className="text-secondary">{op.ip}</td>
                <td className="text-secondary">{op.activityType}</td>
                <td className="text-secondary">{op.errorCode}</td>
                <td className="text-secondary">{op.remarks}</td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>
      <Pagination
        count={totalPage}
        onChange={handleChange}
        className={classes.pagination}
      />
    </>
  );
};

export default OwnOperationLog;
