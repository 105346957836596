import React, {useEffect, useState} from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const Edit = ({
  editModal,
  setEditModal,
  username,
  id,
  setRole,
  givenRole,
}) => {
  const classes = useStyles();
  const [copy, setCopy] = useState([]);
  const handleClose = () => {
    setEditModal(false);
  };

  useEffect(() => {
    if (id != null && editModal) {
      axiosInstance
      .get(`/agent/agents/${id}`)
      .then((res) => {
        console.log(res.data);
        const form = document.getElementById('editForm');
        form['name'].value = res.data.name;
        form['mobile'].value = res.data.mobile;
        form['credit'].value = res.data.credit;
        form['remark'].value = res.data.remarks;
        if (res.data.status=== true) form['status'].value = 1;
        else form['status'].value = 0;
      })
      .catch((err) => console.log(err));

      axiosInstance
      .get('agent/copies', {
        params: {
          type: givenRole.toUpperCase(),
        },
      })
      .then((res) => {
        console.log(res.data);
        setCopy(res.data);
      })
      .catch((err) => console.log(err));
    }
  }, [id, editModal]);

  const handleEdit = (e) => {
    e.preventDefault();

    const requestBody = {
      name: e.target.name.value !== '' ? e.target.name.value : null,
      status: e.target.status.value !== ''? e.target.status.value : null,
      mobile: e.target.mobile.value !== '' ? e.target.mobile.value : null,
      copy: e.target.copyFrom.value !== '' ? e.target.copyFrom.value : null,
      remarks: e.target.remark.value !== '' ? e.target.remark.value : null,
      credit: e.target.credit.value !== '' ? e.target.credit.value : null,
    };


    axiosInstance
      .patch(`/agent/agents/${id}`, qs.stringify(requestBody))
      .then((res) => {
        console.log(res);
        setEditModal(false);
        // update role (for optimisation and to avoid exploit get the role drilled down from protected route to edit )
        axiosInstance
          .get(`/agent/agents/?type=${givenRole.toUpperCase()}`)
          .then((res) => {
            console.log(res);
            toast.success('Success!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              });
            setRole(res.data.content);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

  };

  return (
    <Modal
      open={editModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form action="" onSubmit={handleEdit} id="editForm">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className="bg-primary text-light font-weight-bold"
                >
                  Edit {givenRole}
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              <tr>
                <td className="text-body font-weight-bold">Username:</td>
                <td>{username}</td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Name</td>
                <td>
                  <input type="text" id="name"/>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Status</td>
                <td>
                  <select name="" id="status">
                    <option value={1}>Active</option>
                    <option value={0}>InActive</option>
                  </select>
                  <a className="text-danger">**Disabling this agent will automatically disable all downline agents/players.</a>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Mobile</td>
                <td>
                  <input type="tel" id="mobile" />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Credit</td>
                <td>
                  <input
                    type="text"
                    placeholder="0.00"
                    id="credit"
                  />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Copy From</td>
                <td>
                  <select name="" id="copyFrom">
                    <option value="">None</option>
                    {copy.map((cpy, index) =>
                      cpy.val !== username? (
                      <option value={cpy.val} key={index}>
                        {cpy.val}
                      </option>
                      ) : null
                    )}
                  </select>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Remark</td>
                <td>
                  <textarea
                    name=""
                    id="remark"
                    cols="30"
                    rows="3"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-sm btn-primary mx-3 px-3 py-1"
                    />

                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        setEditModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </Modal>
  );
};

export default Edit;
