import axiosInstance from '../../config';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
const Login = () => {
  const history = useHistory();

  useEffect(() => {
    if(localStorage.getItem('token')) {
      history.push(`/dashboard`);
    }
  }, [history]);

  

  const handleLogin = (e) => {
    e.preventDefault();
    const loginForm = document.getElementById('loginForm');
    const password = loginForm['password'].value;
    const username = loginForm['username'].value;
    // getting token and role and saving them in localStorage
    const data = {
      username: username,
      password: password,
    };
    axiosInstance
      .post('/authenticate', data)
      .then((res) => {
        console.log(res);
        if (res.data.role === 'AGENT' || res.data.role === 'MASTER' || res.data.role === 'SENIOR' || res.data.role === 'VENDOR' || res.data.role === 'COMPANY' || res.data.role === 'SUBAGENT'){
          localStorage.setItem('me', username.toUpperCase().trim());
          localStorage.setItem('token', res.data.jwttoken);
          localStorage.setItem('role', res.data.role);
          setInterval(() => {
            axiosInstance
              .post('token/renew')
              .then((res) => {
                localStorage.setItem('token', res.data.jwttoken);
              })
              .catch((err) => console.log(err));
          }, 600000);
          history.push(`/dashboard`);
        }
        else {
          axiosInstance
            .post('/token/invalidate')
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="card mx-auto mt-5 " style={{ width: '18rem' }}>
      <div className="card-body">
        <h3 className="card-title text-center">Agent Platform</h3>
        {/* <h6 className="card-subtitle mb-2 text-muted text-center">
          
        </h6> */}
        <form onSubmit={handleLogin} id="loginForm">
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" className="form-control" id="username" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" className="form-control" id="password" />
          </div>

          <button type="submit" className="btn btn-sm btn-primary  ">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
