import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import Pagination from '@material-ui/lab/Pagination';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Navbar from '../../components/Navbar';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  reject: {
    textDecoration: 'line-through',
  },
  compName: {
    color: '#0E2336',
    fontWeight: 'bold',
  },
  teams: {
    color: '#323030',
  },
  strong: {
    color: '#DC252F',
  },
  handiCap: {
    color: '#1452CC ',
    fontWeight: 'bold',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const GameCategory = ( {User}) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reports, setReports] = useState([]);
  const [totalTicket, setTotalTicket] = useState(0);
  const [totalMember, setTotalMember] = useState(0);
  const [totalAgent, setTotalAgent] = useState(0);
  const [totalMaster, setTotalMaster] = useState(0);
  const [totalSenior, setTotalSenior] = useState(0);
  const [totalVendor, setTotalVendor] = useState(0);
  const [totalCompany, setTotalCompany] = useState(0);
  const [filter, setFilter] = useState([]);
  const [gcFilter, setGcFilter] = useState(null);
  const [gameType, setGameType] = useState(null);
  const [bets, setBets] = useState(null);
  const [totalPage, setTotalPage] = useState(null);
  const [playerWager, setPlayerWager] = useState(0);
  const [playerWinning, setPlayerWinning] = useState(0);
  const history = useHistory();
  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    setBets(null);
    setGameType(null);
    axiosInstance
      .get('agent/gameCategoryReport', {
        params: {
          gameCategoryFilter: gcFilter? gcFilter: document.getElementById('gcFilter').value,
          startDate: startDate? moment(startDate).format('DD-MM-YYYY'):null,
          endDate: endDate? moment(endDate).format('DD-MM-YYYY'): null
        },
      })
      .then((res) => {
        setReports(res.data);
        let sumTicket = 0, sumMember =0, sumAgent = 0, sumMaster = 0, sumSenior = 0, sumVendor = 0, sumCompany = 0;
        for (let i = 0; i < res.data.length; i++){
          sumTicket += res.data[i].ticket;
          sumMember += res.data[i].memberWinning;
          sumAgent += res.data[i].agentWinning;
          sumMaster += res.data[i].masterWinning;
          sumSenior += res.data[i].seniorWinning;
          sumVendor += res.data[i].vendorWinning;
          sumCompany += res.data[i].companyWinning;
        }
        setTotalTicket(sumTicket);
        setTotalMember(sumMember);
        setTotalAgent(sumAgent);
        setTotalMaster(sumMaster);
        setTotalSenior(sumSenior);
        setTotalVendor(sumVendor);
        setTotalCompany(sumCompany);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  const getDetails = (e, value) => {
    if (e != null) e.preventDefault();
    if (value == null) value = 1;
    setReports(null);
    // console.log
    axiosInstance
      .get('agent/gameCategoryReport/details', {
        params: {
          gameCategoryFilter: gcFilter? gcFilter: document.getElementById('gcFilter').value,
          gameType: gameType,
          startDate: startDate? moment(startDate).format('DD-MM-YYYY'):null,
          endDate: endDate? moment(endDate).format('DD-MM-YYYY'): null,
          pageNo: isNaN(value) ? null : value - 1,
        },
      })
      .then((res) => {
        setBets(res.data.content);
        calculateBet(res.data.content);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  const calculateBet = (report) => {
    let wager = 0;
    let winning = 0;
    for (var i = 0; i < report.length; i++) {
      wager += report[i].amount;
      if (report[i].winning) winning+= report[i].winning;
    }
    setPlayerWager(wager);
    setPlayerWinning(winning);
  }

  useEffect(() => {
    if (gameType != null) {
      getDetails(null);
    }
  }, [gameType]);

  useEffect(() => {
    handleChange(null);
    if (filter.length === 0) {
      axiosInstance
        .get('agent/gameCategoryReport/selectGameCategoryFilter')
        .then((res) => {
          setFilter(res.data);
        });
    }
  }, [startDate, endDate, gcFilter]);

  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={handleChange} id="form">
          Start Date:
          <DatePicker
            selected={startDate ? startDate: new Date()}
            onChange={(date) => setStartDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
          End Date:
          <DatePicker
            selected={endDate ? endDate: new Date()}
            onChange={(date) => setEndDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
          Status:
          <select
            name="gcFilter"
            id="gcFilter"
            className="mx-2"
            // value={gcFilter}
            onChange={(e) => setGcFilter(e.target.value) }
          >
            {filter !== null ? (
              <>
                {filter.map((info) => (
                  <option key={info} value={info}>{info}</option>
                ))}
              </>
            ) : null }
          </select>
        </form>
      </div>
      <div className="table-responsive-sm mx-2">
      {reports !== null ? (
        <table className="table table-sm table-striped table-bordered">
          <thead className="text-center thead-dark">
            <tr>
              <th scope="col">Game Category</th>
              <th scope="col">Ticket</th>
              <th scope="col">Member</th>
              <th scope="col">Agent</th>
              <th scope="col">Master</th>
              <th scope="col">Senior</th>
              <th scope="col">Vendor</th>
              <th scope="col">Company</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((info, index) => (
              <tr key={index}>
                <td className="text-dark text-left">
                  {
                    info.gameCategory.substring(0,2) === 'AH'? 'Asian Handicap': 
                    info.gameCategory.substring(0,2) === 'OU'? 'Over Under': 
                    info.gameCategory.substring(0,3) === '1X2'? '1 X 2': 
                    info.gameCategory.substring(0,2) === 'OE'? 'Odd Even': 
                    info.gameCategory.substring(0,2) === 'TG'? 'Total Goal': 
                    info.gameCategory.substring(0,2) === 'CS'? 'Correct Score': info.gameCategory.charAt(0).toUpperCase()+info.gameCategory.slice(1).toLowerCase().replace('_',' ')
                  }
                  {
                    info.gameCategory.endsWith('FH')? ' (First Half)': null
                  }
                  {info.ticket > 0? (
                    <>
                      {' '}
                      <button
                        className="btn btn-link text-primary p-0 "
                        onClick={() => {
                          setGameType(info.gameCategory);
                        }}
                      >
                        Detail
                      </button>
                    </>
                  ): null}
                </td>
                <td className="text-dark text-right">{info.ticket}</td>
                <td className="text-dark text-right"><CurrencyFormat value={info.memberWinning} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className="text-dark text-right"><CurrencyFormat value={info.agentWinning} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className="text-dark text-right"><CurrencyFormat value={info.masterWinning} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className="text-dark text-right"><CurrencyFormat value={info.seniorWinning} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className="text-dark text-right"><CurrencyFormat value={info.vendorWinning} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className="text-dark text-right"><CurrencyFormat value={info.companyWinning} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
              </tr>
            ))}
          </tbody>
          <tfoot className="text-center thead-light">
            <tr>
              <th className="text-dark text-center">Total</th>
              <th className="text-dark text-right">{totalTicket}</th>
              <th className="text-dark text-right"><CurrencyFormat value={totalMember} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></th>
              <th className="text-dark text-right"><CurrencyFormat value={totalAgent} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></th>
              <th className="text-dark text-right"><CurrencyFormat value={totalMaster} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></th>
              <th className="text-dark text-right"><CurrencyFormat value={totalSenior} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></th>
              <th className="text-dark text-right"><CurrencyFormat value={totalVendor} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></th>
              <th className="text-dark text-right"><CurrencyFormat value={totalCompany} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></th>
            </tr>
          </tfoot>
        </table>
      ) : null}
      { bets != null ? (
        <>
          <button
            className="btn btn-link text-primary p-0 "
            onClick={() => {
              handleChange(null);
            }}
          >
            Back
          </button>
          <table className="table table-sm table-striped">
            <thead className="text-center thead-dark">
              <tr>
                <th scope="col">No</th>
                <th scope="col">Username</th>
                <th scope="col">Date</th>
                <th scope="col">Reference</th>
                <th scope="col">Bet Type</th>
                <th scope="col">Bet Details</th>
                <th scope="col">Amount</th>
                <th scope="col">Result</th>
                <th scope="col">Winning</th>
              </tr>
            </thead>
            <tbody>
              {bets.map((bet, index) => (
                <tr className={classes.border} key={index}>
                  <td className="text-dark">{index + 1}</td>
                  <td className="text-dark text-center">{bet.account}</td>
                  <td className="text-dark text-center">
                    {moment(bet.created).format('DD/MM/YYYY HH:mm')}
                  </td>
                  <td className="text-dark text-center">{bet.reference}</td>
                  <td className="text-dark text-left">
                    {bet.market === 'PARLAY' ? (
                      bet.type
                    ) : (
                      <>
                        {bet.sport} {bet.market}
                        <br />
                        {bet.game}
                      </>
                    )}
                  </td>
                  <td className="text-dark text-left">
                    {bet.market === 'PARLAY' ? (
                      <>
                        Mix Parlay @ {bet.odds.toFixed(2)}
                        <table
                          className="table table-sm table-striped"
                          width="300"
                        >
                          {bet.parlays.map((parlay) => (
                            <>
                              <tr>
                                <td width="200">
                                  <span
                                    className={`${classes.compName} ${
                                      parlay.status === 'REJECTED'
                                        ? classes.reject
                                        : null
                                    }`}
                                  >
                                    {parlay.comp}
                                  </span>
                                  <br />
                                  <span
                                    className={`${classes.teams} ${
                                      parlay.status === 'REJECTED'
                                        ? classes.reject
                                        : null
                                    }`}
                                  >
                                    <span
                                      className={
                                        parlay.high === 1
                                          ? classes.strong
                                          : null
                                      }
                                    >
                                      {parlay.home} vs{' '}
                                    </span>
                                    <span
                                      className={
                                        parlay.high === 2
                                          ? classes.strong
                                          : null
                                      }
                                    >
                                      {parlay.away}
                                    </span>
                                  </span>
                                  <br />
                                  {parlay.market === 'LIVE' ? (
                                    <>
                                      <span className={classes.running}>
                                        (Running {parlay.homeGoal}-
                                        {parlay.awayGoal})
                                      </span>
                                      <br />
                                    </>
                                  ) : null}
                                  <span
                                    className={`${classes.handiCap} ${
                                      parlay.status === 'REJECTED'
                                        ? classes.reject
                                        : null
                                    }`}
                                  >
                                    <span className={classes.compName}>
                                      {parlay.desc}{' '}
                                      {parlay.handicap !== '' ? (
                                        <span className={classes.handiCap}>
                                          ({parlay.handicap}){' '}
                                        </span>
                                      ) : null}
                                      <span>
                                        @ {parlay.odds.toFixed(2)}
                                      </span>
                                    </span>
                                  </span>
                                </td>
                                <td width="50">
                                  {parlay.ftHome}-{parlay.ftAway} (
                                  {parlay.fhHome}-{parlay.fhAway})
                                </td>
                                <td width="50">
                                  {parlay.result.charAt(0).toUpperCase() +
                                    parlay.result
                                      .slice(1)
                                      .toLowerCase()
                                      .replace('_', ' ')}
                                </td>
                              </tr>
                            </>
                          ))}
                        </table>
                      </>
                    ) : (
                      <>
                        <span
                          className={`${classes.compName} ${
                            bet.status === 'REJECTED'
                              ? classes.reject
                              : null
                          }`}
                        >
                          {bet.comp}
                        </span>
                        <br />
                        <span
                          className={`${classes.teams} ${
                            bet.status === 'REJECTED'
                              ? classes.reject
                              : null
                          }`}
                        >
                          <span
                            className={
                              bet.high === 1 ? classes.strong : null
                            }
                          >
                            {bet.home} vs{' '}
                          </span>
                          <span
                            className={
                              bet.high === 2 ? classes.strong : null
                            }
                          >
                            {bet.away}
                          </span>
                        </span>
                        <br />
                        {bet.market === 'LIVE' ? (
                          <>
                            <span className={classes.running}>
                              (Running {bet.homeGoal}-{bet.awayGoal})
                            </span>
                            <br />
                          </>
                        ) : null}
                        <span
                          className={`${classes.handiCap} ${
                            bet.status === 'REJECTED'
                              ? classes.reject
                              : null
                          }`}
                        >
                          <span className={classes.compName}>
                            {bet.desc}{' '}
                            {bet.handicap !== '' ? (
                              <span className={classes.handiCap}>
                                ({bet.handicap}){' '}
                              </span>
                            ) : null}
                            <span>@ {bet.odds.toFixed(2)}</span>
                          </span>
                        </span>
                      </>
                    )}
                  </td>
                  <td className="text-dark text-right">
                    <CurrencyFormat
                      value={bet.amount}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className="text-dark text-center">
                    {bet.result.charAt(0).toUpperCase() +
                      bet.result.slice(1).toLowerCase().replace('_', ' ')}
                  </td>
                  <td className="text-dark text-right">
                    <CurrencyFormat
                      value={bet.winning}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="text-dark text-center" colSpan="6">Total</td>
                <td className={`text-right`}><CurrencyFormat value={playerWager} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td>&nbsp;</td>
                <td className={`text-right`}><CurrencyFormat value={playerWinning} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
              </tr>
            </tfoot>
          </table>
          <Pagination
            count={totalPage}
            onChange={getDetails}
            className={classes.pagination}
          />
        </>
      ) : null}
      </div>
    </>
  );
};

export default GameCategory;
