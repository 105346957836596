import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Navbar from '../../components/Navbar';
import BetList from '../../components/BetList';
import moment from 'moment';

const WlDetail = ( {summaryView, type, out, User} ) => {
  const [username, setUsername] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [resp, setResp] = useState(null);
  const [reports, setReports] = useState([]);
  const [bets, setBets] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [searchUsername, setSearchUsername] = useState(null);
  const [searchClick, setSearchClick] = useState(null);
  const [sumWager, setSumWager] = useState(0);
  const [sumTurnover, setSumTurnover] = useState(0);
  const [sumValid, setSumValid] = useState(0);
  const [sumPAgent, setSumPAgent] = useState(0);
  const [sumPMaster, setSumPMaster] = useState(0);
  const [sumPSenior, setSumPSenior] = useState(0);
  const [sumPVendor, setSumPVendor] = useState(0);
  const [sumPCompany, setSumPCompany] = useState(0);
  const [sumPUpline, setSumPUpline] = useState(0);
  const [sumWlAgent, setSumWlAgent] = useState(0);
  const [sumWlMaster, setSumWlMaster] = useState(0);
  const [sumWlSenior, setSumWlSenior] = useState(0);
  const [sumWlVendor, setSumWlVendor] = useState(0);
  const [sumWlCompany, setSumWlCompany] = useState(0);
  const [sumWlUpline, setSumWlUpline] = useState(0);
  const [sumCommAgent, setSumCommAgent] = useState(0);
  const [sumCommMaster, setSumCommMaster] = useState(0);
  const [sumCommSenior, setSumCommSenior] = useState(0);
  const [sumCommVendor, setSumCommVendor] = useState(0);
  const [sumCommCompany, setSumCommCompany] = useState(0);
  const [sumCommGross, setSumCommGross] = useState(0);
  const [sumCommUpline, setSumCommUpline] = useState(0);
  const [sumWlPlayer, setSumWlPlayer] = useState(0);
  const [playerWager, setPlayerWager] = useState(0);
  const [playerWinning, setPlayerWinning] = useState(0);
  const [searchDate, setSearchDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [searchAp, setSearchAp] = useState(null);
  const [searchMp, setSearchMp] = useState(null);
  const [searchSp, setSearchSp] = useState(null);
  const [searchVp, setSearchVp] = useState(null);
  const [searchCp, setSearchCp] = useState(null);
  const [searchType, setSearchType] = useState(null);
  const [searchParlay, setSearchParlay] = useState(null);
  const [searchTO, setSearchTO] = useState(null);
  const history = useHistory();
  const handleSearch = () => {
    axiosInstance
      .get('agent/wlDetail', {
        params: {
          username: username ? username : null,
          searchUsername: searchUsername ? searchUsername : null,
          startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
          endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
        },
      })
      .then((res) => {
        setReports(res.data);
        calculateSum(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  const calculateSum = (report) => {
    let wager = 0;
    let turnover = 0;
    let valid = 0;
    // let stakeAgent = 0, stakeMaster = 0, stakeSenior = 0, stakeVendor = 0, stakeCompany = 0;
    let pAgent = 0, pMaster = 0, pSenior = 0, pVendor = 0, pCompany = 0, pUpline = 0;
    let wlAgent = 0, wlMaster = 0, wlSenior = 0, wlVendor = 0, wlCompany = 0, wlPlayer = 0, wlUpline = 0;
    let commAgent = 0, commMaster = 0, commSenior = 0, commVendor = 0, commCompany = 0, commUpline = 0;
    let commGross = 0;

    for (var i = 0; i < report.length; i++) {
      wager += report[i].wager;
      turnover += report[i].amount;
      valid += report[i].turnover;
      // if (report[i].playerWin) wlPlayer += report[i].playerWin;
      // if (report[i].agentContra) stakeAgent += report[i].agentContra;
      // if (report[i].masterContra) stakeMaster += report[i].masterContra;
      // if (report[i].seniorContra) stakeSenior += report[i].seniorContra;
      // if (report[i].vendorContra) stakeVendor += report[i].vendorContra;
      // if (report[i].companyContra) stakeCompany += report[i].companyContra;
      if (report[i].playerWin) wlPlayer += report[i].playerWin;
      if (report[i].agentWin) wlAgent += report[i].agentWin;
      if (report[i].masterWin) wlMaster += report[i].masterWin;
      if (report[i].seniorWin) wlSenior += report[i].seniorWin;
      if (report[i].vendorWin) wlVendor += report[i].vendorWin;
      if (report[i].companyWin) wlCompany += report[i].companyWin;
      if (report[i].agentCom) commAgent+= report[i].agentCom;
      if (report[i].masterCom) commMaster+= report[i].masterCom;
      if (report[i].seniorCom) commSenior+= report[i].seniorCom;
      if (report[i].vendorCom) commVendor+= report[i].vendorCom;
      if (report[i].companyCom) commCompany+= report[i].companyCom;
      if (report[i].grossCom) commGross+= report[i].grossCom;
      if (report[i].uplineWin) wlUpline += report[i].uplineWin;
      if (report[i].uplineCom) commUpline += report[i].uplineCom;
      if (report[i].companyPercentage) pCompany += report[i].companyPercentage;
      if (report[i].vendorPercentage) pVendor += report[i].vendorPercentage;
      if (report[i].seniorPercentage) pSenior += report[i].seniorPercentage;
      if (report[i].masterPercentage) pMaster += report[i].masterPercentage;
      if (report[i].agentPercentage) pAgent += report[i].agentPercentage;
      if (report[i].uplinePercentage) pUpline += report[i].uplinePercentage;
    }
    setSumWager(wager);
    setSumTurnover(turnover);
    setSumValid(valid);
    setSumWlAgent(wlAgent);
    setSumWlMaster(wlMaster);
    setSumWlSenior(wlSenior);
    setSumWlVendor(wlVendor);
    setSumWlCompany(wlCompany);
    setSumWlUpline(wlUpline);
    setSumPAgent(pAgent);
    setSumPMaster(pMaster);
    setSumPSenior(pSenior);
    setSumPVendor(pVendor);
    setSumPCompany(pCompany);
    setSumPUpline(pUpline);
    setSumCommAgent(commAgent);
    setSumCommMaster(commMaster);
    setSumCommSenior(commSenior);
    setSumCommVendor(commVendor);
    setSumCommCompany(commCompany);
    setSumCommUpline(commUpline);
    setSumCommGross(commGross);
    setSumWlPlayer(wlPlayer);
  }

  const calculateBet = (report) => {
    let wager = 0;
    let winning = 0;
    for (var i = 0; i < report.length; i++) {
      wager += report[i].amount;
      if (report[i].winning) winning+= report[i].winning;
    }
    setPlayerWager(wager);
    setPlayerWinning(winning);
  }

  const clearForm = () => {
    setBets([]);
    if (searchParlay === null) setSearchType(null);
    setSearchTO(null);
    setSearchParlay(null);
    document.getElementById('member-login').value = '';
    setSearchUsername(null);
  };

  const backToParent = () => {
    clearForm();
    if (
      username != null &&
      username.length > 0 &&
      username !== localStorage.getItem('me')
    ) {
      if (username.length > 8) setUsername(username.substring(0, 8));
      else setUsername(username.substring(0, username.length - 2));
    }
  };
  const handleChange = (e, value) => {
    // setResp(null);
    // setBets([]);
    if (e != null) e.preventDefault();
    let url = 'agent/wlDetail';
    if (type != null && type === 'Y' && out != null && out === 'O') url = 'agent/outstandingType';
    else if (type != null && type === 'Y') url = 'agent/wlType';
    else if (out != null && out === 'O') url = 'agent/outstanding';
    if (searchParlay != null) {
      let pUrl = 'agent/wlDetails/bets';
      if (out != null && out === 'O') pUrl = 'agent/outstanding/bets';
      axiosInstance
        .get(pUrl, {
          params: {
            username: username ? username : localStorage.getItem('me'),
            startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            parlay: searchParlay ? searchParlay : null,
            wc: true,
            pageNo: isNaN(value) ? null : value - 1,
          },
        })
        .then((res) => {
          setBets(res.data.content);
          calculateBet(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else if (searchTO != null) {
      let toUrl = 'agent/wlDetails/bets';
      if (out != null && out === 'O') toUrl = 'agent/outstanding/bets';
      axiosInstance
        .get(toUrl, {
          params: {
            username: searchTO,
            startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            wc: true,
            agent: searchAp ? searchAp : null,
            master: searchMp ? searchMp : null,
            senior: searchSp ? searchSp : null,
            vendor: searchVp ? searchVp : null,
            company: searchCp ? searchCp : null,
            pageNo: isNaN(value) ? null : value - 1,
          },
        })
        .then((res) => {
          setBets(res.data.content);
          calculateBet(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else if (searchType === null && (username == null || username.length <= 8)) {
      axiosInstance
        .get(url, {
          params: {
            username: username ? (username === 'COMPANY'? null: username) : null,
            searchUsername: searchUsername ? searchUsername : null,
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            summary: summaryView != null? summaryView : null
          },
        })
        .then((res) => {
          setResp(res.data);
          setReports(res.data.content);
          calculateSum(res.data.content);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else if (searchType != null && searchType === 'PARLAY') {
      let typeParlayUrl = 'agent/wlParlay';
      if (out != null && out === 'O') typeParlayUrl = 'agent/outstandingParlay';
      axiosInstance
        .get(typeParlayUrl, {
          params: {
            username: username ? (username === 'COMPANY'? null: username) : null,
            searchUsername: searchUsername ? searchUsername : null,
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            summary: summaryView != null? summaryView : null
          },
        })
        .then((res) => {
          setResp(res.data);
          setReports(res.data.content);
          calculateSum(res.data.content);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else if (searchType != null && searchType !== 'PARLAY') {
      let typeUrl = 'agent/wlDetails/bets';
      if (out != null && out === 'O') typeUrl = 'agent/outstanding/bets';
      axiosInstance
        .get(typeUrl, {
          params: {
            username: username ? username : localStorage.getItem('me'),
            startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            game: searchType,
            wc: true,
            pageNo: isNaN(value) ? null : value - 1,
          },
        })
        .then((res) => {
          setBets(res.data.content);
          calculateBet(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    }else {
      if (value == null) value = 1;
      let betUrl = 'agent/wlDetails/bets';
      if (out != null && out === 'O') betUrl = 'agent/outstanding/bets';
      axiosInstance
        .get(betUrl, {
          params: {
            username: username ? username : null,
            startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            agent: searchAp ? searchAp : null,
            master: searchMp ? searchMp : null,
            senior: searchSp ? searchSp : null,
            vendor: searchVp ? searchVp : null,
            company: searchCp ? searchCp : null,
            pageNo: isNaN(value) ? null : value - 1,
          },
        })
        .then((res) => {
          setBets(res.data.content);
          calculateBet(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    }
  };

  const getToday = (minus) => {
    axiosInstance
      .get('public/today')
      .then((res) => {
        setStartDate(null);
        setEndDate(null);
        let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        today.setDate(today.getDate() - minus);
        setStartDate(today);
        setEndDate(today);
        setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
        let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
    });
  }

  const getWeek = (minus) => {
    axiosInstance
      .get('public/today')
      .then((res) => {
        setStartDate(null);
        setEndDate(null);
        let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        let today2 = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        let diff = today.getDay() - 1;
        if (diff < 0) diff += 7;
        today.setDate(today.getDate() - diff);
        if (minus > 0){
          today.setTime(today.getTime() - minus * 7 * 24*60*60*1000);
          today2.setTime(today.getTime() + 6 * 24*60*60*1000);
        }
        setStartDate(today);
        setEndDate(today2);
        setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
        let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
    });
  }

  const getMonth = (minus) => {
    axiosInstance
      .get('public/today')
      .then((res) => {
        setStartDate(null);
        setEndDate(null);
        let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        let today2 = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        if (minus > 0) {
          today.setMonth(today.getMonth() - minus);
          if (minus > 1) today2.setMonth(today2.getMonth() - minus + 1);
          today2.setDate(minus - 1);
        }
        today.setDate(1);
        setStartDate(today);
        setEndDate(today2);
        setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
        let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
    });
  }

  const getMemberHeader = () => {
    return (
      <>
        <th scope="col">Win/Lose</th>
        <th scope="col">Comm</th>
        <th scope="col">Total</th>
      </>
    );
  }

  const getAgentHeader = () => {
    return (
      <>
        <th scope="col">{type === 'Y'? 'Turnover': '%'}</th>
        <th scope="col">Win/Lose</th>
        <th scope="col">Comm</th>
        <th scope="col">Total</th>
      </>
    );
  }
  const generateRow = (percent, wl, com, sm) => {
    return (
      <>
        {percent != null && type === 'Y'? (
          <td className={`text-right${percent < 0?' text-danger':''}`}><CurrencyFormat value={percent} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
        ) : percent != null? (
          <td className={`text-right text-info${percent < 0?' text-danger':''}`}><CurrencyFormat value={percent} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
        ) : null}
        {wl != null? (
          <td className={`text-right${wl < 0?' text-danger':''}`}><CurrencyFormat value={wl} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
        ) : null}
        {com != null? (
          <td className={`text-right${com < 0?' text-danger':''}`}><CurrencyFormat value={com} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
        ) : null}
        {sm ? (
          <td className={`text-right${wl + com < 0?' text-danger':''}`}><CurrencyFormat value={wl + com} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
        ) : null}
      </>
    )
  }
  const moneyTd = (amt) => {
    return (
      <td className={`text-right${amt < 0?' text-danger':''}`}><CurrencyFormat value={amt} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
    );
  }
  useEffect(() => {
    setSearchClick(false);
    if (username == null) {
      // setUsername(localStorage.getItem('me'));
      // setLevel(localStorage.getItem('role'));
    }
    // if (startDate == null || endDate == null) {
    //   axiosInstance
    //     .get('public/today')
    //     .then((res) => {
    //       if (startDate == null)  setStartDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
    //       if (endDate == null)  setEndDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
    //       setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
    //       let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
    //       minSearchDate.setDate(minSearchDate.getDate() - 90);
    //       setSearchDate(minSearchDate);
    //   });
    // }
    if (startDate != null && endDate != null && endDate >= startDate) handleChange(null);
  }, [username, startDate, endDate, searchType, searchTO, searchParlay]);

  useEffect(() => {
    axiosInstance
        .get('public/today')
        .then((res) => {
          if (startDate == null)  setStartDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
          if (endDate == null)  setEndDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
          setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
          let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
          minSearchDate.setDate(minSearchDate.getDate() - 90);
          setSearchDate(minSearchDate);
      });
    setSearchTO(null);
    setSearchType(null);
    handleChange(null);
  }, [history.location]);
  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={handleChange} id="form">
          Start Date:
          <DatePicker
            selected={startDate ? startDate : new Date()}
            minDate={searchDate}
            onChange={(date) => setStartDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
          End Date:
          <DatePicker
            selected={endDate ? endDate : new Date()}
            onChange={(date) => setEndDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
            minDate={startDate}
            maxDate={maxDate}
          />
          <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getToday(0);
              }}
            >
              Today
          </button>{' '}
          <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getToday(1);
              }}
            >
              Yesterday
            </button>{' '}
          <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getWeek(0);
              }}
            >
              This Week
          </button>{' '}
          <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getWeek(1);
              }}
            >
              Last Week
            </button>{' '}
            <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getMonth(0);
              }}
            >
              This Month
            </button>{' '}
            <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getMonth(1);
              }}
            >
              Last Month
            </button>{' '}
          {/* Login ID{' '} */}
          <input
            type="hidden"
            id="member-login"
            value={searchUsername}
            onChange={(event) => setSearchUsername(event.target.value)}
          />
          {/* &nbsp;&nbsp;&nbsp;
          <input
            type="submit"
            value="Search"
            className="btn btn-sm btn-primary px-3 py-1"
            onClick={() => {
              setSearchClick(true);
            }} */}
          {/* /> */}
        </form>
      </div>
      {resp !== null ? (
        <div className="table-responsive-sm mx-2">
          {(username != null &&
          username.length > 0 &&
          username !== localStorage.getItem('me')) || searchType != null || searchTO != null ? (
            <button
              className="btn btn-sm btn-primary px-1 py-1 my-2"
              onClick={() => {
                backToParent();
              }}
            >
              Return
            </button>
          ) : null}
          {(searchType === null || (searchType === 'PARLAY' && searchParlay === null)) && searchTO === null && (username == null || username.length <= 8) ? (
            <>
              <table className="table table-sm table-striped table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    { type === 'Y'? (
                      <th scope="col" rowSpan={2} colSpan={2}>
                        Type
                      </th>
                    ): (
                      <>
                        <th scope="col" rowSpan={2}>
                          Name
                        </th>
                        <th scope="col" rowSpan={2}>
                          Login<br/>ID
                        </th>
                      </>
                    )}
                    
                    <th scope="col" rowSpan={2}>
                      Currency
                    </th>
                    <th scope="col" rowSpan={2}>
                      Wager
                    </th>
                    <th scope="col" rowSpan={2}>
                      Turn Over
                    </th>
                    <th scope="col" rowSpan={2}>
                      Valid<br/>Turn Over
                    </th>
                    <th scope="col" rowSpan={2}>
                      Gross<br/>Comm
                    </th>
                    {resp.member? (
                      <th scope="col" colSpan={3}>
                        Member
                      </th>
                    ): null}
                    {resp.agent? (
                      <th scope="col" colSpan={4}>
                        Agent
                      </th>
                    ): null}
                    {resp.master? (
                      <th scope="col" colSpan={4}>
                        Master Agent
                      </th>
                    ): null}
                    {resp.senior? (
                      <th scope="col" colSpan={4}>
                        Senior Agent
                      </th>
                    ): null}
                    {resp.vendor? (
                      <th scope="col" colSpan={4}>
                        Vendor
                      </th>
                    ): null}
                    <th scope="col" colSpan={4}>
                      Company
                    </th>
                  </tr>
                  <tr>
                    { resp.member? getMemberHeader() : null }
                    { resp.agent? getAgentHeader() : null } 
                    { resp.master? getAgentHeader() : null }
                    { resp.senior? getAgentHeader() : null }
                    { resp.vendor? getAgentHeader() : null }
                    { getAgentHeader() }
                  </tr>
                </thead>
                <tbody>
                  {reports.map((info, index) => (
                    <tr key={index}>
                      { type === 'Y'? (
                        <td colSpan={2}>
                          { searchType === 'PARLAY'? (
                            <button
                              className="btn btn-link text-primary p-0 "
                              onClick={() => {
                                setSearchParlay(info.matches);
                              }}>{info.matches} Matches
                            </button>
                          ):(
                            <button
                              className="btn btn-link text-primary p-0 "
                              onClick={() => {
                                setSearchType(info.code);
                              }}>{info.name}
                            </button>
                          )}
                          
                        </td>
                      ):(
                        <>
                          <td className="text-center">{info.name}</td>
                          <td className="text-center">
                            <button
                              className="btn btn-link text-primary p-0 "
                              onClick={() => {
                                setUsername(info.username);
                                if (info.username.length > 8){
                                  if (info.agentPercentage) setSearchAp(info.agentPercentage); else setSearchAp(null);
                                  if (info.masterPercentage) setSearchMp(info.masterPercentage); else setSearchMp(null);
                                  if (info.seniorPercentage) setSearchSp(info.seniorPercentage); else setSearchSp(null);
                                  if (info.vendorPercentage) setSearchVp(info.vendorPercentage); else setSearchVp(null);
                                  if (info.companyPercentage) setSearchCp(info.companyPercentage); else setSearchCp(null);
                                }
                                clearForm();
                              }}
                            >
                              {info.username}
                            </button>
                          </td>
                        </>
                      )}
                      
                      <td className="text-center">{info.currency}</td>
                      <td className="text-center">{info.wager}</td>

                      { type === 'Y' ?  moneyTd(info.amount) : (
                        <td className={`text-right${info.amount < 0?' text-danger':''}`}>
                          <button
                            className="btn btn-link text-primary p-0 "
                            onClick={() => {
                              if (info.agentPercentage) setSearchAp(info.agentPercentage); else setSearchAp(null);
                              if (info.masterPercentage) setSearchMp(info.masterPercentage); else setSearchMp(null);
                              if (info.seniorPercentage) setSearchSp(info.seniorPercentage); else setSearchSp(null);
                              if (info.vendorPercentage) setSearchVp(info.vendorPercentage); else setSearchVp(null);
                              if (info.companyPercentage) setSearchCp(info.companyPercentage); else setSearchCp(null);
                              setSearchTO(info.username);
                              // setSearchType(info.code);
                            }}>
                            <CurrencyFormat value={info.amount} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/>
                          </button>
                        </td>
                      ) }
                      { moneyTd(info.turnover) }
                      { moneyTd(info.grossCom) }
                      { resp.member? generateRow(null, info.playerWin, 0, 1) : null }
                      { resp.agent? generateRow(info.agentPercentage, info.agentWin, info.agentCom, 1) : null }
                      { resp.master? generateRow(info.masterPercentage, info.masterWin, info.masterCom, 1) : null }
                      { resp.senior? generateRow(info.seniorPercentage, info.seniorWin, info.seniorCom, 1) : null }
                      { resp.vendor? generateRow(info.vendorPercentage, info.vendorWin, info.vendorCom, 1) : null }
                      { resp.company? generateRow(info.companyPercentage, info.companyWin, info.companyCom, 1) : 
                        generateRow(info.uplinePercentage, info.uplineWin, info.uplineCom, 1)
                      }
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="text-center" colSpan="3">Total</td>
                    <td className={`text-center`}>{sumWager}</td>
                    { moneyTd(sumTurnover) }
                    { moneyTd(sumValid) }
                    { moneyTd(sumCommGross) }
                    { resp.member? generateRow(null, sumWlPlayer, 0, 1) : null }
                      { resp.agent? (
                        <>
                          { type === 'Y'? moneyTd(sumPAgent) : (
                            <td className={`text-right`}> </td>
                          )}
                          { generateRow(null, sumWlAgent, sumCommAgent, 1) } 
                        </>
                      ): null }
                      { resp.master? (
                        <>
                          { type === 'Y'? moneyTd(sumPMaster) : (
                            <td className={`text-right`}> </td>
                          )} 
                           { generateRow(null, sumWlMaster, sumCommMaster, 1) }
                        </>
                      ): null }
                      { resp.senior? (
                        <>
                          { type === 'Y' ? moneyTd(sumPSenior) : (
                            <td className={`text-right`}> </td>
                          )}
                          { generateRow(null, sumWlSenior, sumCommSenior, 1) }
                        </>
                      ): null }
                      { resp.vendor? (
                        <>
                          { type === 'Y' ? moneyTd(sumPVendor) : (
                            <td className={`text-right`}> </td>
                          )}
                          { generateRow(null, sumWlVendor, sumCommVendor, 1) }
                        </> 
                      ): null }
                      { resp.coompany? (
                        <>
                          { type === 'Y'? moneyTd(sumPCompany): (
                            <td className={`text-right`}> </td>
                          )}
                          { generateRow(null, sumWlCompany, sumCommCompany, 1) }
                        </>
                        ): ( 
                          <>
                            { type === 'Y' ? moneyTd(sumPUpline) : (
                              <td className={`text-right`}> </td>
                            )}
                            { generateRow(null, sumWlUpline, sumCommUpline, 1) }
                          </>
                        )
                      }
                  </tr>
                </tfoot>
              </table>
            </>
          ) : (
            <>
              <BetList bets={bets} handleChange={handleChange} playerWager={playerWager} playerWinning={playerWinning} totalPage={totalPage} />
            </>
          )}
        </div>
      ) : null}
    </>
  );
};

export default WlDetail;
