import React, { useEffect, useState } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import axiosInstance from '../config';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [User, setUser] = useState(null);
  const [username, setUsername] = useState('');
  let history = useHistory();

  useEffect(() => {
    axiosInstance
      .get('agent/me')
      .then((res) => {
        setUsername(res.data.username);
        setUser(res.data);
        setIsAuthenticated(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        history.push('/');
      });
  }, [history]);

  if (isLoading === true) {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} User={User} username={username} />
        )
      }
    />
  );
};

export default ProtectedRoute;
