import { makeStyles } from '@material-ui/core';
import axiosInstance from '../../config';
import React, { useEffect, useState, useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import { Modal } from '@material-ui/core';
import qs from 'querystring';
import Edit from './functions/Edit';
import Setting from './functions/Setting';
import Password from '../../components/Password';
import CopySetting from './functions/CopySetting';
import Pagination from '@material-ui/lab/Pagination';
import Navbar from '../../components/Navbar';
import Deposit from './functions/Deposit';
import Withdraw from './functions/Withdraw';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  borderInactive: {
    backgroundColor: '#e1e1e1',
  },
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const Member = ({ username, User }) => {
  const classes = useStyles();
  const [member, setMember] = useState(null);
  // modal
  const [open, setOpen] = useState(false);
  // currencies
  const [currency, setCurrency] = useState([]);
  // sort by
  const [Sort, setSort] = useState([]);
  // login id for popup
  const [ids, setId] = useState([]);
  // array for options
  const number = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  // copyFrom
  const [copy, setCopy] = useState([]);

  // modal for edit
  const [editModal, setEditModal] = useState(false);
  // pagination
  const [totalPage, setTotalPage] = useState(null);
  // modal for password
  const [passwordModal, setPasswordModal] = useState(false);
  // modal for deposit
  const [depositModal, setDepositModal] = useState(false);
  // modal for withdraw
  const [withdrawModal, setWithdrawModal] = useState(false);
  // modal for setting
  const [settingModal, setSettingModal] = useState(false);
  // modal for copy
  const [copyModal, setCopyModal] = useState(false);
  // for passing value inside edit function also in password modal
  const editUsername = useRef('');
  // for passing user id in op log
  const memberId = useRef('');
  //  for passing name in deposit
  const name = useRef('');
  // for passing balance to deposit
  const balance = useRef('');
  // data to be passed in deposit modal
  const [deposit, setDeposit] = useState(null);
  // data to be passed in withdraw modal
  const [withdraw, setWithdraw] = useState(null);
  const [sumCredit, setSumCredit] = useState(0);
  const [sumBalance, setSumBalance] = useState(0);

  // handle deposit
  const handleDeposit = (username) => {
    // get amount for deposit
    axiosInstance
      .get('/agent/txns', {
        params: {
          username: username,
          type: 'DEPOSIT',
        },
      })
      .then((res) => {
        console.log(res.data);
        setDeposit(res.data);
      })
      .catch((err) => console.log(err));
  };
  // handle withdraw
  const handleWithdraw = (username) => {
    // get amount for withdraw
    axiosInstance
      .get('/agent/txns', {
        params: {
          username: username,
          type: 'WITHDRAW',
        },
      })
      .then((res) => {
        console.log(res.data);
        setWithdraw(res.data);
      })
      .catch((err) => console.log(err));
  };

  const sumCredBalance = (members) => {
    let sumC = 0;
    let sumB = 0;
    for (let i = 0; i < members.length; i++) {
      sumC += members[i].credit;
      sumB += members[i].availableBalance;
    }
    setSumCredit(sumC);
    setSumBalance(sumB);
  };

  // handle operation log
  const handleOpLog = () => {
    window.open(`/operationm?id=${memberId.current}`, 'blank');
  };

  // handle credit log
  const handleCredit = () => {
    window.open(`/creditm?id=${memberId.current}`, 'blank');
  };

  // handle memeber search
  const handleMemberSearch = (e, page) => {
    e.preventDefault();
    const memberForm = document.getElementById('member-form');
    const searchName = memberForm['member-name'].value;
    const searchUser = memberForm['member-login'].value;
    const searchStatus = memberForm['member-status'].value;
    const sort = memberForm['member-sort'].value;
    const asc = memberForm['member-asc'].value;
    axiosInstance
      .get('agent/members', {
        params: {
          searchName: searchName !== '' ? searchName : null,
          searchUser: searchUser !== '' ? searchUser : null,
          searchStatus: searchStatus !== '' ? searchStatus : null,
          sort: sort !== '' ? sort : null,
          asc: asc !== '' ? asc : null,
          pageNo: isNaN(page) ? null : page - 1,
        },
      })
      .then((res) => {
        console.log(res.data);
        setMember(res.data.content);
        sumCredBalance(res.data.content);
      })
      .catch((err) => console.log(err));
  };

  // handle create member
  const handleMember = (e) => {
    e.preventDefault();
    const form = document.getElementById('memberForm');
    const prefix = form['prefix'].value;
    const no1 = form['no1'].value;
    const no2 = form['no2'].value;
    const no3 = form['no3'].value;
    const mb_username = `${no1}${no2}${no3}`;
    const mb_name = form['name'].value;
    const password = form['pass'].value;
    const myPassword = form['agentPass'].value;
    // const credit = form['credit'].value;
    const mb_copy = form['copyFrom'].value;
    const status = form['status'].value;
    const mobile = form['mobile'].value;
    const remarks = form['remark'].value;

    const requestBody = {
      prefix: prefix,
      username: mb_username,
      name: mb_name,
      password: password,
      mobile: mobile,
      copy: mb_copy,
      status: status,
      remarks: remarks,
      // credit: credit,
      myPassword: myPassword,
    };

    axiosInstance
      .post('agent/members', qs.stringify(requestBody))
      .then((res) => {
        console.log(res.data);
        toast.success('Success!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        // refreshing the memebers and closing the modal
        axiosInstance
          .get('/agent/members')
          .then((res) => {
            setMember(res.data.content);
            setTotalPage(res.data.totalPages);
            sumCredBalance(res.data.content);
          })
          .catch((err) => console.log(err));
        setOpen(false);
      })
      .catch((err) => console.log(err));
  };
  // on change of user update copy
  const handleCopy = (e) => {
    axiosInstance
      .get('agent/members/copies', {
        params: {
          agent: e.target.value,
        },
      })
      .then((res) => {
        console.log(res.data);
        setCopy(res.data);
      })
      .catch((err) => console.log(err));
  };

  // modal handling
  const handleView = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // load member when component mounts
  useEffect(() => {
    axiosInstance
      .get('/agent/members')
      .then((res) => {
        console.log(res);
        setMember(res.data.content);
        setTotalPage(res.data.totalPages);
        sumCredBalance(res.data.content);
      })
      .catch((err) => console.log(err));

    // currencies for new member addition
    axiosInstance
      .get('/agent/currencies')
      .then((res) => {
        console.log(res.data);
        setCurrency(res.data);
      })
      .catch((err) => console.log(err));
    // sort by option
    axiosInstance
      .get('/agent/sort ')
      .then((res) => {
        console.log(res.data);
        setSort(res.data);
      })
      .catch((err) => console.log(err));
    // getting users for popup modal
    axiosInstance
      .get('agent/uplines')
      .then((res) => {
        console.log(res.data);
        setId(res.data);
        // getting copy from
        axiosInstance
          .get('agent/members/copies', {
            params: {
              agent: res.data[0].val,
            },
          })
          .then((res) => {
            console.log(res.data);
            setCopy(res.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <Navbar User={User} username={username} />
      {member !== null ? (
        <>
          <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
            <form
              action=""
              className="form-inline"
              id="member-form"
              onSubmit={handleMemberSearch}
            >
              <label
                htmlFor="member-name"
                className="text-body font-weight-bold mx-2 ml-0"
              >
                Name{' '}
              </label>
              <input
                type="text"
                id="member-name"
                className="form-control form-control-xs"
              />
              <label
                htmlFor="member-login"
                className="text-body font-weight-bold mx-2"
              >
                Login Id{' '}
              </label>
              <input
                type="text"
                id="member-login"
                className="form-control form-control-xs"
              />
              <label
                htmlFor="member-status"
                className="text-body font-weight-bold mx-2"
              >
                Status{' '}
              </label>
              <select
                name=""
                id="member-status"
                className="form-control form-control-xs"
              >
                {/* <select name="" id="member-status" className="form-control form-control-xs"> */}
                <option value="">All</option>
                <option value={true}>Active</option>
                <option value={false}>InActive</option>
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label
                htmlFor="member-sort"
                className="text-body font-weight-bold mx-2"
              >
                Sort by{' '}
              </label>
              <select
                name=""
                id="member-sort"
                className="form-control form-control-xs"
              >
                {/* <option value="">None</option> */}
                {Sort.map((srt, index) => (
                  <option value={srt.key} key={index}>
                    {srt.val}
                  </option>
                ))}
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <select
                name=""
                id="member-asc"
                className="form-control form-control-xs mx-2"
              >
                {/* <option value="">None</option> */}
                <option value={1}>Asc</option>
                <option value={0}>Desc</option>
              </select>
              <input
                type="submit"
                value="Search"
                className="btn btn-sm btn-primary px-3 py-1 "
              />
              &nbsp;&nbsp;&nbsp;
            </form>
            { User.privUser == null || (User.privUser === true && User.privUserMember === true) ? (
            <button
              className="btn btn-sm btn-primary px-3 py-1 "
              onClick={handleView}
            >
            Create Member
            </button>) : null}
          </div>
          <div className="table-responsive-sm mx-2">
            <table className="table table-sm table-hover table-bordered">
              <thead className="text-center thead-dark">
                <tr>
                  <th>No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Login Id</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Given Credit</th>
                  <th scope="col">Cash Balance</th>
                  <th scope="col">Last Login Time</th>
                  <th scope="col">Create Time</th>
                  <th scope="col">Status</th>
                  <th scope="col">Function</th>
                </tr>
              </thead>
              <tbody>
                {member.map((info, index) => (
                  <tr
                    className={`${
                      info.status === true ? '' : classes.borderInactive
                    }`}
                    key={index}
                  >
                    <td className="text-dark text-center">{index + 1}</td>
                    <td className="text-dark">{info.name}</td>
                    <td className="text-dark">{info.username}</td>
                    <td className="text-dark text-center">{info.currency}</td>
                    <td className="text-dark text-right">
                      <CurrencyFormat
                        value={info.credit}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td className="text-dark text-right">
                      <CurrencyFormat
                        value={info.availableBalance}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td className="text-dark text-center">
                      {info.lastLogin === null ? (
                        '-'
                      ) : (
                        <>{moment(info.lastLogin).format('DD/MM/YYYY HH:mm')} </>
                      )}
                    </td>
                    <td className="text-dark text-center">
                      {moment(info.createdAt).format('DD/MM/YYYY HH:mm')}
                    </td>
                    {info.status === true ? (
                      <td className="text-dark text-center">Active</td>
                    ) : (
                      <td className="text-danger text-center">InActive</td>
                    )}
                    <td className="text-primary">
                      { User.privUser == null || (User.privUser === true && User.privUserSetting === true) ? (
                        <>
                          <button
                            className="btn btn-link text-primary p-0 "
                            onClick={() => {
                              memberId.current = info.id;
                              editUsername.current = info.username;
                              setEditModal(true);
                            }}
                          >
                            Edit
                          </button>
                          /
                        </>
                      ): null}
                      { User.privUser == null || (User.privUser === true && User.privUserSetting === true) ? (
                        <>
                          <button
                            className="btn btn-sm btn-link text-primary p-0"
                            onClick={() => {
                              memberId.current = info.id;
                              editUsername.current = info.username;
                              setSettingModal(true);
                            }}
                          >
                            Setting
                          </button>
                          /
                          <button
                            className="btn btn-sm btn-link text-primary p-0"
                            onClick={() => {
                              memberId.current = info.id;
                              editUsername.current = info.username;
                              setCopyModal(true);
                            }}
                          >
                            Copy Setting
                          </button>
                          /
                        </>
                      ): null}
                      <button
                        className="btn btn-sm btn-link text-primary p-0"
                        onClick={() => {
                          memberId.current = info.id;
                          handleCredit();
                        }}
                      >
                        Credit Log
                      </button>
                      /
                      <button
                        className="btn btn-sm btn-link text-primary p-0"
                        onClick={() => {
                          memberId.current = info.id;
                          handleOpLog();
                        }}
                      >
                        Operation Log
                      </button>
                      { User.privUser == null || (User.privUser === true && User.privUserPassword === true) ? (
                        <>
                          /
                          <button
                            className="btn btn-sm btn-link text-primary p-0"
                            onClick={() => {
                              editUsername.current = info.username;
                              setPasswordModal(true);
                            }}
                          >
                            Password
                          </button>
                        </>
                      ): null}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="text-dark text-center" colSpan="4">
                    Total
                  </td>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={sumCredit}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={sumBalance}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td colSpan="4"></td>
                </tr>
              </tfoot>
            </table>
          </div>
          <Pagination
            count={totalPage}
            onChange={handleMemberSearch}
            className={classes.pagination}
          />
          {/* modal */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modalStyle}
          >
            <div className={classes.Modal}>
              <form onSubmit={handleMember} id="memberForm">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th
                        colSpan="2"
                        className="bg-primary text-light font-weight-bold"
                      >
                        New Member
                      </th>
                    </tr>
                  </thead>
                  <tbody className={classes.tableBody}>
                    <tr>
                      <td className="text-body font-weight-bold ">
                        Your Password
                      </td>
                      <td>
                        <input type="password" id="agentPass" required />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">Login Id</td>
                      <td>
                        <select name="" id="prefix" onChange={handleCopy}>
                          {ids.map((id, index) => (
                            <option value={id.val} key={index}>
                              {id.val}
                            </option>
                          ))}
                        </select>
                        <select name="" className="mx-2" id="no1">
                          {number.map((option, index) => (
                            <option value={option} key={index}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <select name="" className="mx-2" id="no2">
                          {number.map((option, index) => (
                            <option value={option} key={index}>
                              {option}
                            </option>
                          ))}
                          <option value=""></option>
                        </select>
                        <select name="" className="mx-2" id="no3">
                          {number.map((option, index) => (
                            <option value={option} key={index}>
                              {option}
                            </option>
                          ))}
                          <option value=""></option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">Name</td>
                      <td>
                        <input type="text" id="name" required />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">Password</td>
                      <td>
                        <input type="password" required />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">
                        Confirm Password
                      </td>
                      <td>
                        <input type="password" id="pass" required />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">Status</td>
                      <td>
                        <select name="" id="status">
                          <option value={1}>Active</option>
                          <option value={2}>InActive</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">Currency</td>
                      <td>
                        <select name="" id="">
                          {currency.map((currency, i) => (
                            <option value="" key={i}>
                              {currency.val}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="text-body font-weight-bold ">
                        Given Credit
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="0.00"
                          id="credit"
                          required
                        />
                      </td>
                    </tr> */}
                    <tr>
                      <td className="text-body font-weight-bold ">Mobile</td>
                      <td>
                        <input type="tel" id="mobile" />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">Remark</td>
                      <td>
                        <textarea
                          name=""
                          id="remark"
                          cols="30"
                          rows="3"
                        ></textarea>
                      </td>
                    </tr>

                    <tr>
                      <td className="text-body font-weight-bold ">Copy From</td>
                      <td>
                        <select name="" id="copyFrom">
                          <option value="">None</option>
                          {copy.map((cpy, index) => (
                            <option value={cpy.val} key={index}>
                              {cpy.val}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div className="d-flex justify-content-end">
                          <input
                            type="submit"
                            value="Submit"
                            className="btn btn-sm btn-primary mx-3 px-3 py-1"
                          />

                          <button
                            className="btn btn-sm btn-danger px-3 py-1"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </Modal>
        </>
      ) : null}
      {/* functions */}

      {/* edit modal */}
      <Edit
        setMember={setMember}
        id={memberId.current}
        editModal={editModal}
        setEditModal={setEditModal}
        givenRole="Member"
        copy={copy}
        username={editUsername.current}
      />
      {/* password modal */}
      <Password
        passwordModal={passwordModal}
        setPasswordModal={setPasswordModal}
        username={editUsername.current}
      ></Password>
      {/* deposit modal  */}
      <Deposit
        setTotalPage={setTotalPage}
        setMember={setMember}
        balance={balance.current}
        name={name.current}
        depositModal={depositModal}
        setDepositModal={setDepositModal}
        username={editUsername.current}
        deposit={deposit}
      />
      {/* withdraw modal */}
      <Withdraw
        setTotalPage={setTotalPage}
        setMember={setMember}
        balance={balance.current}
        name={name.current}
        withdrawModal={withdrawModal}
        setWithdrawModal={setWithdrawModal}
        username={editUsername.current}
        withdraw={withdraw}
      />
      {/* setting modal */}
      <Setting
        setSettingModal={setSettingModal}
        settingModal={settingModal}
        id={memberId.current}
        username={editUsername.current}
      />
      {/* copy setting modal */}
      <CopySetting
        copy={copy}
        copyModal={copyModal}
        setCopyModal={setCopyModal}
        username={editUsername.current}
        id={memberId.current}
        setMember={setMember}
      />
    </>
  );
};

export default Member;
