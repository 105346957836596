import React from 'react';
import { Link } from 'react-router-dom';

const BalNavBar = ({ isBalNavbar }) => {
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light bg-light py-0 border-bottom ${
        isBalNavbar !== true ? 'd-none' : 'd-block'
      }`}
      id="bal-navbar"
    >
      <div>
        <div className="navbar-nav position-relative">
          <Link
            className={`nav-link text-dark${
              window.location.pathname === '/winlose' ? ' active' : ''
            }`}
            to="/winlose"
          >
            Win/Lose
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default BalNavBar;
