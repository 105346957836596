import React, { useEffect, useState } from 'react';
import axiosInstance from '../../config';
import SubTable from '../../components/SubTable';
import CreateSub from '../../components/CreateSub';
import Navbar from '../../components/Navbar';
import OpLog from '../../components/OpLog';

const Sub = ({ User, username }) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [type, setType] = useState(null);
  // data for table in agent
  const [agent, setAgent] = useState(null);
  // sort by
  const [Sort, setSort] = useState([]);
  // pagination
  const [totalPage, setTotalPage] = useState(null);
  const [opOpen, setOpOpen] = useState(false);

  //  handle search
  const editSub = (subUser) => {
    setType('U');
    setUser(subUser);
    setOpen(true);
  }

  const resetForm = () => {
    setType(null);
    setUser(null);
    setOpen(false);
  }

  const handleRoleSearch = (e, page) => {
    if (e != null) e.preventDefault();
    const memberForm = document.getElementById('searchform');
    const searchName = memberForm['name'].value;
    const searchUser = memberForm['login'].value;
    const searchStatus = memberForm['status'].value;
    const sort = memberForm['sort'].value;
    const asc = memberForm['asc'].value;
    axiosInstance
      .get('agent/subagents', {
        params: {
          searchName: searchName !== '' ? searchName : null,
          searchUser: searchUser !== '' ? searchUser : null,
          searchStatus: searchStatus !== '' ? searchStatus : null,
          sort: sort !== '' ? sort : null,
          asc: asc !== '' ? asc : null,
          pageNo: isNaN(page) ? null : page - 1,
        },
      })
      .then((res) => {
        setTotalPage(res.data.totalPages);
        setAgent(res.data.content);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axiosInstance
      .get('/agent/subagents')
      .then((res) => {
        console.log(res.data);
        setTotalPage(res.data.totalPages);
        setAgent(res.data.content);
      })
      .catch((err) => console.log(err));
    // sort by option
    axiosInstance
      .get('/agent/sort')
      .then((res) => {
        console.log(res.data);
        setSort(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navbar username={username} User={User} />

      {agent !== null && opOpen === false ? (
        <>
          <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
            <form
              action=""
              className="form-inline"
              id="searchform"
              onSubmit={handleRoleSearch}
            >
              <label
                htmlFor="name"
                className="text-body font-weight-bold mx-2 ml-0"
              >
                Name{' '}
              </label>
              <input
                type="text"
                id="name"
                className="form-control form-control-xs"
              />
              <label
                htmlFor="login"
                className="text-body font-weight-bold mx-2"
              >
                Login Id{' '}
              </label>
              <input
                type="text"
                id="login"
                className="form-control form-control-xs"
              />
              <label
                htmlFor="status"
                className="text-body font-weight-bold mx-2"
              >
                Status{' '}
              </label>
              <select
                name=""
                id="status"
                className="form-control form-control-xs"
              >
                {/* <select name="" id="status" className="form-control form-control-xs"> */}
                <option value="">All</option>
                <option value={true}>Active</option>
                <option value={false}>InActive</option>
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label htmlFor="sort" className="text-body font-weight-bold mx-2">
                Sort by{' '}
              </label>
              <select
                name=""
                id="sort"
                className="form-control form-control-xs"
              >
                {/* <option value="">None</option> */}
                {Sort.map((srt, index) => (
                  <option value={srt.key} key={index}>
                    {srt.val}
                  </option>
                ))}
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <select
                name=""
                id="asc"
                className="form-control form-control-xs mx-2"
              >
                {/* <option value="">None</option> */}
                <option value={1}>Asc</option>
                <option value={0}>Desc</option>
              </select>
              <input
                type="submit"
                value="Search"
                className="btn btn-sm btn-primary px-3 py-1 "
              />
              &nbsp;&nbsp;&nbsp;
            </form>
            <button
              className="btn btn-sm btn-primary px-3 py-1 "
              onClick={() => {
                setOpen(true);
              }}
            >
              Create Sub
            </button>
          </div>
          <SubTable
            subs={agent}
            totalPage={totalPage}
            handleRoleSearch={handleRoleSearch}
            editSub={editSub}
            resetForm={resetForm}
            setUser={setUser}
            setOpsModal={setOpOpen}
          />
          {/* modal for create agent */}
          <CreateSub
            open={open}
            setOpen={setOpen}
            handleRoleSearch={handleRoleSearch}
            type={type}
            user={user}
            resetForm={resetForm}
          />    
        </>
        
      ) : null}
      <OpLog
            sub='Y'
            open={opOpen}
            setOpen={setOpOpen}
            username={user} 
            setUsername={setUser}
          />
    </>
  );
};

export default Sub;
